import { render, staticRenderFns } from "./officeArea.vue?vue&type=template&id=030c8af6&scoped=true&"
import script from "./officeArea.vue?vue&type=script&lang=js&"
export * from "./officeArea.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "030c8af6",
  null
  
)

export default component.exports