<template>
  <div>
    <el-row class="input Part">
      <el-button size="mini" type="primary" @click="addWarehouse" v-if="authe.Hotel_RegionalDivision_OfficeArea_AddOfficeArea">添加办公室</el-button>
      <el-button size="mini" type="primary" @click="updateWarehouse" v-if="authe.Hotel_RegionalDivision_OfficeArea_UpdateOfficeArea">修改办公室</el-button>
        <el-dialog :title="wareTitle" :visible.sync="dialogWarehouse" @close="dialogWarehouseClose" :close-on-click-modal="false" v-loading="loading" width="400px">
          <el-form size="mini" label-width="80px">
            <el-form-item label="办公室状态" v-if="wareTitle=='修改办公室'">
              <el-select v-model="addData.hsState" style="width:280px">
                <el-option label="" value=""></el-option>
                <el-option label="正常" value="正常"></el-option>
                <el-option label="停用" value="停用"></el-option>
                <el-option label="注销" value="注销"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="名称">
              <el-input v-model="addData.hsAddCommunity" clearable style="width:280px"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="addData.hsHouseNote" type="textarea" :rows="2" clearable style="width:280px"></el-input>
            </el-form-item>
          </el-form>

          <div slot="footer">
            <el-button size="mini" @click="dialogWarehouse=false">关闭</el-button>
            <el-button size="mini" type="primary" @click="saveRoom">保存</el-button>
          </div>
        </el-dialog>
      <!--  筛选框 -->
      <el-form inline label-width="75px">
        <el-form-item label="办公室名称">
          <el-input @input="searchOnkeyup(getHomeData)" v-model="homeParms.hsAddCommunity" clearable size="mini" style="width:140px"></el-input>
        </el-form-item>
        <el-form-item label="备注描述">
          <el-input @input="searchOnkeyup(getHomeData)" v-model="homeParms.hsHouseNote" clearable size="mini" style="width:140px"></el-input>
        </el-form-item>
        <el-form-item label="办公室状态">
          <el-select @change="getHomeData(1)" v-model="homeParms.hsState" clearable size="mini" style="width:140px">
            <el-option label="" value=""></el-option>
            <el-option label="正常" value="正常"></el-option>
            <el-option label="停用" value="停用"></el-option>
            <el-option label="注销" value="注销"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-row>
    <el-table :data="homeData.records" @row-click="homeDataClick" @row-dblclick="homeDataDblclick" :height="tableHeight" highlight-current-row style="width: 100%">
      <el-table-column prop="hsAddCommunity" align="center" label="办公室名称"></el-table-column>
      <el-table-column prop="hsHouseNote" align="center" label="备注描述"></el-table-column>
      <el-table-column prop="hsState" align="center" label="状态"></el-table-column>
    </el-table>
    <el-pagination
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="[5,10,20,40]"
      :page-size="homeParms.limit"
      :current-page="homeParms.cursor"
      :total="homeData.total"
      align="center"
      background
      @current-change="meterCurChange"
      @size-change="meterSizeChange"
    ></el-pagination>
    <el-dialog title="办公室详细信息" @close="dialogInfoClose" v-loading="loading" :visible.sync="dialogInfo" :close-on-click-modal="false" width="1100px">
      <el-tabs v-model="activeName" style="height:550px;overflow-y:auto">
        <el-tab-pane label="办公室详细" name="public">
          <el-form inline>
            <el-row>
              <el-col :span="6">
                <el-form-item label="办公室名称: " label-width="80px">
                  {{homeRow.hsAddCommunity}}
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="分类: " label-width="80px">
                  {{homeRow.hsAddCity}}
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="状态: " label-width="80px">
                  {{homeRow.hsState}}
                </el-form-item>
              </el-col>
              <!-- <el-col :span="6">
                <el-form-item label="联系人: " label-width="80px">
                  {{homeRow.keyNumber}}
                </el-form-item>
              </el-col> -->
            </el-row>
            <el-row>
              <!-- <el-col :span="6">
                <el-form-item label="联系电话: " label-width="80px">
                  {{homeRow.houseEntrust4rent}}
                </el-form-item>
              </el-col> -->
              <!-- <el-col :span="6">
                <el-form-item label="库房创建人: " label-width="80px">
                  {{homeRow.suStaffName}}
                </el-form-item>
              </el-col> -->
              <el-col :span="12">
                <el-form-item label="备注: " label-width="80px">
                  {{homeRow.hsHouseNote}}
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="资产管理" name="asset" v-if="authe.Hotel_RegionalDivision_OfficeArea_AssetManage">
          <el-row>
            <el-button size="mini" type="primary" @click="addAssetsClick" v-if="authe.Hotel_RegionalDivision_OfficeArea_AddingAssets">添加资产</el-button>
            <el-button size="mini" type="primary" @click="fixAssetsClick" v-if="authe.Hotel_RegionalDivision_OfficeArea_ModifyAssets">修改资产</el-button>
            <el-button size="mini" type="primary" @click="moveOutClick" v-if="authe.Hotel_RegionalDivision_OfficeArea_MigrateAssets">迁移资产</el-button>
            <el-button size="mini" type="primary" @click="printCodeClick" v-if="authe.Hotel_RegionalDivision_OfficeArea_PrintCard">打印勾选资产标识卡</el-button>
              <!-- 添加修改资产弹窗 -->
              <el-dialog width="1000px" v-loading="loading" :title="addAssetsForm.dialogAddElectricTitle" @close="dialogAddElectricClose" :visible.sync="addAssetsForm.dialogAddElectric" :close-on-click-modal="false" append-to-body>
                &nbsp;&nbsp;&nbsp;&nbsp;资产归属<el-input disabled :value="homeRow.hsAddCommunity"  clearable size="mini" style="width:300px"></el-input>
                <el-form ref="electric" :model="addAssetsForm.assets" label-width="75px" :rules="rules" inline >
                  <el-form-item label="资产所属" prop="saType">
                    <el-select v-model="addAssetsForm.assets.saType" size="mini" clearable style="width:120px">
                      <el-option label value></el-option>
                      <el-option label="公司" value="公司"></el-option>
                      <el-option label="租赁" value="租赁"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="使用情况" prop="saUse">
                    <el-select v-model="addAssetsForm.assets.saUse" size="mini" clearable style="width:120px">
                      <el-option v-for="(item,i) in addAssetsForm.saUseList" :key="i" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="资产状态" prop="saStatus">
                    <el-select v-model="addAssetsForm.assets.saStatus" size="mini" clearable style="width:120px">
                      <el-option v-for="(item,i) in addAssetsForm.saStatusList" :key="i" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="价格" prop="saPrice" label-width="50px">
                    <el-input v-model="addAssetsForm.assets.saPrice" clearable size="mini" style="width:120px"></el-input>
                  </el-form-item>
                  <el-form-item label="资产类型" prop="saClassify">
                    <el-select @change="classifyChange" v-model="addAssetsForm.assets.saClassify" size="mini" clearable style="width:120px">
                      <el-option v-for="(item,i) in companyVars.suppliesType" :key="i" :label="item.variable" :value="item.variable"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="名称" prop="saName">
                    <el-select v-model="addAssetsForm.assets.saName" size="mini" clearable style="width:120px">
                      <el-option v-for="(item,i) in addAssetsForm.assetsType" :key="i" :label="item" :value="item"></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="品牌">
                    <el-input v-model="addAssetsForm.assets.saBrand" clearable size="mini" style="width:120px"></el-input>
                  </el-form-item>
                  <el-form-item label="型号" label-width="50px">
                    <el-input v-model="addAssetsForm.assets.saModel" clearable size="mini" style="width:120px"></el-input>
                  </el-form-item>
                  <el-form-item v-show="addAssetsForm.onlyFixAssets==1" label="数量">
                    <el-input v-model="addAssetsForm.numbers" type="tel" oninput="value=value.replace(/[^\d]/g,'')" clearable size="mini" style="width:120px"></el-input>
                  </el-form-item>
                  <el-form-item label="供应商">
                    <el-input v-model="addAssetsForm.assets.saSupplierName" @click.native="selectAddressBelong('选择供应商')" clearable size="mini" style="width:120px"></el-input>
                  </el-form-item>
                  <el-form-item label="备注">
                    <el-input v-model="addAssetsForm.assets.saRemarks" type="textarea" :rows="1" size="mini" style="width:310px" clearable></el-input>
                  </el-form-item>
                  <el-form-item v-show="addAssetsForm.onlyFixAssets==1">
                    <el-button size="mini" type="primary" @click="addAssets2">添加</el-button>
                    <el-button size="mini" type="danger" @click="clearAssets">清除</el-button>
                  </el-form-item>
                  <el-table v-show="addAssetsForm.onlyFixAssets==1" :data="addAssetsForm.assetsPARMS" style="width: 100%" size="mini" height="200px" max-height="200px">
                    <el-table-column prop="assetsBelong" label="地址/项目" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="saType" label="所属"></el-table-column>
                    <el-table-column prop="saClassify" label="类型"></el-table-column>
                    <el-table-column prop="saUse" label="使用"></el-table-column>
                    <el-table-column prop="saStatus" label="状态"></el-table-column>
                    <el-table-column prop="saName" label="名称"></el-table-column>
                    <el-table-column prop="saBrand" label="品牌"></el-table-column>
                    <el-table-column prop="saModel" label="型号"></el-table-column>
                    <el-table-column prop="saPrice" label="价值"></el-table-column>
                    <el-table-column prop="saRemarks" label="备注" show-overflow-tooltip></el-table-column>
                    <el-table-column prop="saSupplierName" label="供应商"></el-table-column>
                    <el-table-column fixed="right" label="删除" width="80">
                      <template slot-scope="scope">
                        <el-button @click.native.prevent="deleteRow(scope.$index, addAssetsForm.assetsPARMS)" type="text" size="small"> 删除 </el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </el-form>
                <div slot="footer">
                  <el-button size="mini" @click="addAssetsForm.dialogAddElectric=false">取消</el-button>
                  <el-button size="mini" type="primary" v-show="addAssetsForm.onlyFixAssets==1" @click="postElectric">保存</el-button>
                  <el-button size="mini" type="primary" v-show="addAssetsForm.onlyFixAssets==2" @click="putElectric">保存</el-button>
                </div>
              </el-dialog>

              <el-dialog width="600px" @close="dialogMoveOutClose" v-loading="loading" title="迁移资产" :visible.sync="moveOutForm.dialogMoveOut" :close-on-click-modal="false" append-to-body>
                <el-form label-width="120px">
                  <el-form-item label="迁出地址">
                    <el-input v-model="moveOutForm.address" size="mini" readonly style="width:400px"></el-input>
                  </el-form-item>
                  <el-form-item label="迁入地址">
                    <el-input :value="roomAddress" @click.native="selectAddressBelong('选择地址')" size="mini" style="width:400px"></el-input>
                  </el-form-item>
                  <el-form-item label="经手人">
                    <el-cascader @change="handIdChange" v-model="moveOutForm.handId" :options="userNameList" filterable clearable  :props="{ expandTrigger: 'hover' }" size="mini" style="width:400px"></el-cascader>
                  </el-form-item>
                  <el-form-item label="迁移原因">
                    <el-input type="textarea" v-model="moveOutForm.moveReason" size="mini" style="width:400px"></el-input>
                  </el-form-item>
                </el-form>
                <div slot="footer">
                  <el-button @click="moveOutForm.dialogMoveOut=false">取消</el-button>
                  <el-button @click="saveMoveOutAss" type="primary">保存</el-button>
                </div>

              </el-dialog>

              <el-dialog width="600px" style="height:600px" title="打印勾选资产标识卡" :visible.sync="printCodeForm.dialogprintCode" :close-on-click-modal="false" append-to-body>
                <div style="width:100%" id="code" ref="code">
                  <div v-for="(item,i) in printCodeForm.urlList" :key="i" style="text-align:center;margin-top:20px">
                    <p style="font-size:18px;line-height:8px">资产标识卡NO.{{item.number}}</p>
                    <div style="display:flex;justify-content:center;">
                      <div>
                        <vue-qr :text="item.url" :margin="0" colorDark="#333" colorLight="#fff" :size="120"></vue-qr>
                      </div>
                      <div style="width:180px;font-size:14px;text-align:left;line-height:8px;margin-left:5px;">
                        <p>名称 {{item.name}}</p>
                        <p>品牌 {{item.brand}}</p>
                        <p>型号 {{item.model}}</p>
                        <p>地址 {{item.address}}</p>
                        <p>状态 {{item.status}}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div slot="footer">
                  <el-button @click="printCodeForm.dialogprintCode=false">取消</el-button>
                  <el-button v-print="'#code'" type="primary">打印</el-button>
                </div>
              </el-dialog>
          </el-row>
          <!-- 头部筛选框开始 -->
          <el-form inline label-width="70px">
            <el-form-item label="资产所属">
              <el-select @change="getAllElectric(1)" v-model="assetForm.params.saType" clearable size="mini" style="width: 120px" >
                <el-option label value></el-option>
                <el-option label="公司" value="公司"></el-option>
                <el-option label="租赁" value="租赁"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="使用情况">
              <el-select @change="getAllElectric(1)" v-model="assetForm.params.saUse" clearable size="mini" style="width: 120px" >
                <el-option label value></el-option>
                <el-option label="未使用" value="未使用"></el-option>
                <el-option label="使用中" value="使用中"></el-option>
                <el-option label="已报废" value="已报废"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资产状态">
              <el-select @change="getAllElectric(1)" v-model="assetForm.params.saStatus" clearable size="mini" style="width: 120px" >
                <el-option label value></el-option>
                <el-option label="正常" value="正常"></el-option>
                <el-option label="损坏" value="损坏"></el-option>
                <el-option label="丢失" value="丢失"></el-option>
                <el-option label="注销" value="注销"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资产编号">
              <el-input @input="searchOnkeyup(getAllElectric)" v-model="assetForm.params.saNumber" clearable size="mini" style="width:120px" ></el-input>
            </el-form-item>
            <el-form-item label="资产类型">
              <el-select @change="getAllElectric(1)" v-model="assetForm.params.saClassify" size="mini" clearable style="width:120px">
                <el-option v-for="(item,i) in companyVars.suppliesType" :key="i" :label="item.variable" :value="item.variable"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="资产名称">
              <el-input @input="searchOnkeyup(getAllElectric)" v-model="assetForm.params.saName" clearable size="mini" style="width:120px" ></el-input>
            </el-form-item>
            <el-form-item label="品牌">
              <el-input @input="searchOnkeyup(getAllElectric)" v-model="assetForm.params.saBrand" clearable size="mini" style="width:120px" ></el-input>
            </el-form-item>
            <el-form-item label="型号">
              <el-input @input="searchOnkeyup(getAllElectric)" v-model="assetForm.params.saModel" clearable size="mini" style="width:120px" ></el-input>
            </el-form-item>
          </el-form>
          <el-table @row-click="homeTableClick" @selection-change="homeTableSelect" :row-class-name="tableRowShowInput" @row-dblclick="homeTableDblclick" ref="homeTable" :cell-style="cellStyle" :data="assetForm.electricList" height="300px" fit show-header size="mini" highlight-current-row style="width: 100%" >
            <el-table-column align="center" type="selection" width="45px"></el-table-column>
            <el-table-column align="center" prop="saNumber" label="资产编号" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column align="center" prop="address" label="地址" min-width="100px" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="saType" label="所属"></el-table-column>
            <el-table-column align="center" prop="saClassify" label="类型"></el-table-column>
            <el-table-column align="center" prop="saName" label="名称"></el-table-column>
            <el-table-column align="center" prop="saBrand" label="品牌"></el-table-column>
            <el-table-column align="center" prop="saModel" label="型号"></el-table-column>
            <el-table-column align="center" prop="saPrice" label="价格"></el-table-column>
            <el-table-column align="center" prop="saDepreciationPrice" label="折旧价格"></el-table-column>
            <el-table-column align="center" prop="saStatus" label="状态"></el-table-column>
            <el-table-column align="center" prop="saUse" label="使用情况"></el-table-column>
            <el-table-column align="center" prop="saPhotosNum" label="图片"></el-table-column>
          </el-table>
          <el-pagination
            background
            :page-size="assetForm.limit"
            align="center"
            :current-page="assetForm.cursor"
            layout="prev, pager, next"
            @current-change="electricCurChange2"
            :total="assetForm.total"
            style="margin-top: 10px">
          </el-pagination>
          <el-dialog width="800px" title="资产详细信息" :visible.sync="assetForm.dialogElectric" :close-on-click-modal="false" append-to-body>
            <el-button size="mini" @click="uploadPhoto">上传及查看图片</el-button>
            <el-form inline label-width="70px">
              <el-form-item label="资产归属">
                <el-input v-model="assetForm.homeRow.address" size="mini" readonly style="width:300px"></el-input>
              </el-form-item>
              <el-form-item label="资产所属">
                <el-input v-model="assetForm.homeRow.saType" size="mini" readonly style="width:100px"></el-input>
              </el-form-item>
              <el-form-item label="使用情况">
                <el-input v-model="assetForm.homeRow.saUse" size="mini" readonly style="width:100px"></el-input>
              </el-form-item>
              <el-form-item label="资产状态">
                <el-input v-model="assetForm.homeRow.saStatus" size="mini" readonly style="width:110px"></el-input>
              </el-form-item>
              <el-form-item label="价格">
                <el-input v-model="assetForm.homeRow.saPrice" size="mini" readonly style="width:110px"></el-input>
              </el-form-item>
              <el-form-item label="资产类型">
                <el-input v-model="assetForm.homeRow.saClassify" size="mini" readonly style="width:100px"></el-input>
              </el-form-item>
              <el-form-item label="名称">
                <el-input v-model="assetForm.homeRow.saName" size="mini" readonly style="width:100px"></el-input>
              </el-form-item>
              <el-form-item label="品牌">
                <el-input v-model="assetForm.homeRow.saBrand" size="mini" readonly style="width:300px"></el-input>
              </el-form-item>
              <el-form-item label="型号">
                <el-input v-model="assetForm.homeRow.saModel" size="mini" readonly style="width:100px"></el-input>
              </el-form-item>
              <el-form-item label="供应商">
                <el-input v-model="assetForm.homeRow.saSupplierName" size="mini" readonly style="width:100px"></el-input>
              </el-form-item>
              <el-form-item label="资产编号">
                <el-input v-model="assetForm.homeRow.saNumber" size="mini" readonly style="width:180px"></el-input>
              </el-form-item>
              <el-form-item label="折旧价格">
                <el-input v-model="assetForm.homeRow.saDepreciationPrice" size="mini" readonly style="width:100px"></el-input>
              </el-form-item>
              <el-form-item label="备注">
                <el-input type="textarea" v-model="assetForm.homeRow.saRemarks" size="mini" readonly style="width:660px"></el-input>
              </el-form-item>
            </el-form>
            <el-table @row-dblclick="saFollowUpClick" :data="assFollowData.records" style="width: 100%" height="200px" size="mini">
              <el-table-column align="center" prop="jhfFollowTime" label="进展时间" min-width="100px" :show-overflow-tooltip="true"></el-table-column>
              <el-table-column align="center" prop="suStaffName" label="跟进人"></el-table-column>
              <el-table-column align="center" prop="jhfPaymentWay" label="跟进类型"></el-table-column>
              <el-table-column align="center" prop="jhfFollowRemark" :show-overflow-tooltip="true" min-width="180px" label="进展描述"></el-table-column>
            </el-table>
            <el-pagination
              background
              :page-size="assFolLimit"
              align="center"
              :current-page="assFolCur"
              layout="prev, pager, next"
              @current-change="assCurChange"
              :total="assFollowData.total">
            </el-pagination>
            <el-dialog width="500px" title="进展详情" :visible.sync="assetForm.dialogFollowup" :close-on-click-modal="false" append-to-body>
              <table class="tableSp" style="margin:auto;width:440px">
                <tbody>
                  <tr>
                    <td >跟进时间</td>
                    <td colspan="3" style="color:blue;">{{assetForm.followupRow.jhfFollowTime}}</td>
                  </tr>
                  <tr>
                    <td >跟进人</td>
                    <td style="color:blue;">{{assetForm.followupRow.suStaffName}}</td>
                    <td >跟进类型</td>
                    <td style="color:blue">{{assetForm.followupRow.jhfPaymentWay}}</td>
                  </tr>
                  <tr>
                    <td>备注</td>
                    <td colspan="3" style="color:blue;">{{assetForm.followupRow.jhfFollowRemark}}</td>
                  </tr>
                </tbody>
              </table>
            </el-dialog>
            <div style="margin-top:10px; text-align:center;">
              <el-button type="primary" @click="prvPrice" size="mini">上一条</el-button>
              <el-button size="mini" @click="assetForm.dialogElectric=false">关闭</el-button>
              <el-button type="primary" @click="nextPrice" size="mini">下一条</el-button>
            </div>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="审批记录" name="approval" v-if="authe.Hotel_RegionalDivision_OfficeArea_ApprovalRecord">
          <el-row>
            <el-button size="mini" type="primary" @click="addApproval" v-if="authe.Hotel_RegionalDivision_OfficeArea_AddApproval">添加审批</el-button>
            <el-button size="mini" type="primary" @click="seePhotoClick" v-if="authe.Hotel_RegionalDivision_OfficeArea_Annex">查看附件</el-button>
              <el-dialog title="添加审批" :visible.sync="dialogAppro" @close="dialogApproClose" :close-on-click-modal="false" v-loading="loading" width="750px" append-to-body>
                <el-row>
                  <el-form size="mini" label-width="75px" inline style="margin-top:10px">
                    <el-form-item label="审批归属">
                      <el-input :value="homeRow.hsAddCommunity" disabled size="mini" style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="审批人">
                      <el-cascader v-if="!isArea" v-model="eaEventHandler" :disabled="addAppro.eaProcessId!='自由流程'" :options="userNameList" filterable :props="{ expandTrigger: 'hover'}" size="mini" style="width:200px"></el-cascader>
                      <el-cascader v-if="isArea" v-model="eaEventHandler" :options="demoOpt" :props="{ expandTrigger: 'hover'}" size="mini" clearable filterable style="width:200px" ></el-cascader>
                    </el-form-item>
                    <el-form-item label="审批类型">
                      <el-select v-model="addAppro.eaProcessId" style="width:140px" @change="eaProcessChange">
                        <el-option label="" value=""></el-option>
                        <el-option v-for="item in appList" :key="item.jeapId" :label="item.jeapType" :value="item.jeapId"></el-option>
                        <el-option label="自由流程" value="自由流程"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="审批编号">
                      <el-input v-model="addAppro.eaApprovalNumber" disabled style="width:140px"></el-input>
                    </el-form-item>
                    <el-form-item label="涉及金额">
                      <el-input v-model="addAppro.eaAmountInvolved" style="width:140px" type="tel" oninput="value=value.replace(/[^\d.-]/g,'')"></el-input>
                    </el-form-item>
                    <el-row v-show="addAppro.eaAmountInvolved">
                      <el-form-item label="账号名称">
                        <el-input v-model="addAppro.eaBankUsername" style="width:140px"></el-input>
                      </el-form-item>
                      <el-form-item label="开户银行">
                        <el-input v-model="addAppro.eaBankName" style="width:140px"></el-input>
                      </el-form-item>
                      <el-form-item label="银行账号">
                        <el-input v-model="addAppro.eaBankAccountNumber" style="width:140px"></el-input>
                      </el-form-item>
                      <el-form-item label="所属支行">
                        <el-input v-model="addAppro.eaSubordinateBranch" style="width:140px"></el-input>
                      </el-form-item>
                      <el-form-item label="账号描述">
                        <el-input v-model="addAppro.eaBankAccountDescription" style="width:140px"></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-button type="primary" size="small" @click="selAcc">选择账号</el-button>
                      </el-form-item>
                    </el-row>
                    <el-form-item label="短信提醒">
                      <el-checkbox v-model="msgChecked"></el-checkbox>
                    </el-form-item>
                    <el-form-item label="优先处理">
                      <el-checkbox v-model="firstCheck"></el-checkbox>
                    </el-form-item>
                    <el-form-item>
                      <el-button size="mini" type="primary" @click="uploadOfAppro">附件</el-button>
                    </el-form-item>
                    <el-form-item label="审批内容">
                      <el-input type="textarea" :rows="3" v-model="addAppro.eaEventContent" style="width:590px"></el-input>
                    </el-form-item>
                  </el-form>
                </el-row>
                <div slot="footer">
                  <el-button size="mini" @click="dialogAppro=false">关闭</el-button>
                  <el-button size="mini" type="primary" @click="saveApproval">保存</el-button>
                </div>
              </el-dialog>
              <el-dialog title="银行账号" :visible.sync="selBankAccDia" width="800px" :close-on-click-modal="false" append-to-body>
                <el-form label-width="70px" size="mini" inline>
                  <el-form-item label="账号名称">
                    <el-input v-model="scAccName" @change="getBankAccData" size="small"></el-input>
                  </el-form-item>
                  <el-form-item label="开户银行">
                    <el-input v-model="scBank" @change="getBankAccData" size="small"></el-input>
                  </el-form-item>
                </el-form>
                <el-table :data="bankAccData.records" style="width: 100%" height="396px" @row-dblclick="dbSelAcc">
                  <el-table-column prop="csAccountName" label="账号名称"></el-table-column>
                  <el-table-column prop="csBank" label="开户银行"></el-table-column>
                  <el-table-column prop="csAccountUmber" label="银行账号"></el-table-column>
                  <el-table-column prop="csSubordinateBranch" label="所属支行"></el-table-column>
                  <el-table-column prop="csAccountDescription" label="账号描述"></el-table-column>
                </el-table>
                <el-pagination
                  background
                  :page-size="bankLim"
                  align="center"
                  :current-page="bankCur"
                  layout="prev, pager, next"
                  @current-change="bankCurChange"
                  :total="bankAccData.total"
                  style="margin-top: 10px">
                </el-pagination>
              </el-dialog>
          </el-row>
          <el-table :data="approvalData.records" @row-dblclick="approvalDblClick" @row-click="approvalClick" :row-class-name="tableRowShowInput" ref="appro" style="width: 100%" height="396px" highlight-current-row size="mini">
            <el-table-column align="center" prop="eaEventState" label="审批状态"></el-table-column>
            <el-table-column align="center" prop="eaEventType" label="审批类型"></el-table-column>
            <el-table-column align="center" prop="eaReleaseTime" label="申请时间" min-width="100px" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="eaEventContent" label="审批内容" min-width="140px" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="eaAmountInvolved" label="涉及金额"></el-table-column>
            <el-table-column align="center" prop="eaImgNum" label="附件数量"></el-table-column>
            <el-table-column align="center" prop="eaCompletionTime" label="完成时间" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="totalPage" label="审批耗时"></el-table-column>
          </el-table>
          <el-pagination
            background
            :page-size="approvalParams.limit"
            align="center"
            :current-page="approvalParams.cursor"
            layout="prev, pager, next"
            @current-change="approCurChange"
            :total="approvalData.total"
            style="margin-top: 10px">
          </el-pagination>
          <el-dialog :title="`查看${approvalRow.eaEventType}审批单`" :visible.sync="dialogAppDbl" :close-on-click-modal="false" v-loading="loading" width="750px" append-to-body>
            <el-row>
              <el-col :span="12">归属地址：{{homeRow.hsAddCommunity}}</el-col>
              <el-col :span="12" style="text-align:right">审批编号：{{approvalRow.eaApprovalNumber}}</el-col>
            </el-row>
            <el-row>
              <table class="tableSp" style="margin:auto;width:690px">
                <tbody>
                  <tr>
                    <td >申请人</td>
                    <td style="color:blue;">{{approvalRow.handlerName}}</td>
                    <td >申请时间</td>
                    <td style="color:blue;">{{approvalRow.eaReleaseTime}}</td>
                  </tr>
                  <tr>
                    <td>申请内容</td>
                    <td colspan="3" style="color:blue;">{{approvalRow.eaEventContent}}</td>
                  </tr>
                  <tr>
                    <td>涉及金额</td>
                    <td colspan="3" style="color:blue;">￥{{approvalRow.eaAmountInvolved}}  (大写：{{this.numberChinese(approvalRow.eaAmountInvolved)}})</td>
                  </tr>
                  <tr v-show="approvalRow.eaBankName || approvalRow.eaBankUsername || approvalRow.eaBankAccountNumber">
                    <td >收款账户</td>
                    <td style="color:blue;">{{approvalRow.eaBankName}}</td>
                    <td style="color:blue">{{approvalRow.eaBankUsername}}</td>
                    <td style="color:blue">{{approvalRow.eaBankAccountNumber}}</td>
                  </tr>
                </tbody>
              </table>
              <el-button size="mini" type="primary" @click="seePhotoClick">附件</el-button>
            </el-row>
            <el-table :data="eaTreatmentOpinion" @row-dblclick="eaTreatmentDblclick" height="200px" highlight-current-row>
              <el-table-column align="center" prop="name" label="审批人"></el-table-column>
              <el-table-column align="center" prop="advise" label="审批意见"></el-table-column>
              <el-table-column align="center" prop="time" label="审批时间"></el-table-column>
            </el-table>
            <el-dialog width="500px" title="详细处理信息" :visible.sync="dialogAppFol" :close-on-click-modal="false" append-to-body>
              <table class="tableSp" style="margin:auto;width:450px">
                <tbody>
                  <tr>
                    <td >审批时间</td>
                    <td style="color:blue;">{{appFollowRow.time}}</td>
                    <td >审批人</td>
                    <td style="color:blue">{{appFollowRow.name}}</td>
                  </tr>
                  <tr>
                    <td >审批意见</td>
                    <td colspan="3" style="color:blue;">{{appFollowRow.advise}}</td>
                  </tr>
                </tbody>
              </table>
            </el-dialog>
            <table class="tableSp" style="margin:auto;width:450px" v-if="approvalRow.eaWhetherGenerateRecord=='是'">
                <tbody>
                  <tr>
                    <td >操作人</td>
                    <td style="color:blue;">{{approvalRow.handlerName}}</td>
                    <td >付款金额</td>
                    <td style="color:blue;">￥{{approvalRow.eaAmountInvolved}}</td>
                    <td >财务流水号</td>
                    <td style="color:blue;">{{approvalRow.eaFinancialCoding}}</td>
                  </tr>
                  <tr>
                    <td>付款时间</td>
                    <td colspan="" style="color:blue;">{{approvalRow.jfCheckInTime}}</td>
                    <td>付款账户</td>
                    <td colspan="3" style="color:blue;">{{approvalRow.payInfo}}</td>
                  </tr>
                </tbody>
              </table>
            <div slot="footer" align="center">
              <el-button type="primary" @click="approPrv">上一条</el-button>
              <el-button type="primary" @click="approNext">下一条</el-button>
            </div>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="任务记录" name="task" v-if="authe.Hotel_RegionalDivision_OfficeArea_TaskRecord">
          <el-row>
            <el-button size="mini" type="primary" @click="addTaskClick" v-if="authe.Hotel_RegionalDivision_OfficeArea_AddTask">添加任务</el-button>
              <el-dialog title="添加任务" :visible.sync="dialogTask" @close="dialogTaskClose" :close-on-click-modal="false" v-loading="loading" width="550px" append-to-body>
                <el-row>
                  <el-form size="mini" label-width="75px" inline style="margin-top:10px">
                    <el-form-item label="负责人">
                      <el-cascader v-model="repRepairPeopleId" :options="userNameList" filterable clearable  :props="{ expandTrigger: 'hover' }" size="mini" style="width:170px"></el-cascader>
                    </el-form-item>
                    <el-form-item label="任务类型">
                      <el-select v-model="addTask.repTypeRp" size="mini" style="width: 170px">
                        <el-option v-for="item in companyVars.taskTypeList" :key="item.variable" :label="item.variable" :value="item.variable"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="客户姓名">
                      <el-input v-model="addTask.repContacts" style="width:170px"></el-input>
                    </el-form-item>
                    <el-form-item label="客户电话">
                      <el-input v-model="addTask.repContactsPhone" style="width:170px"></el-input>
                    </el-form-item>
                    <el-form-item label="期望时间">
                      <el-select v-model="addTask.repHopeTime" size="mini" style="width: 170px">
                        <el-option v-for="item in timeList" :key="item" :label="item" :value="item"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="任务等级">
                      <el-select v-model.lazy="addTask.repGrade" size="mini" clearable style="width:170px">
                        <el-option label="1" value="1"></el-option>
                        <el-option label="2" value="2"></el-option>
                        <el-option label="3" value="3"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item>
                      <el-button type="primary" @click="uploadOfTask">附件</el-button>
                    </el-form-item>
                    <el-form-item label="短信提醒">
                      <el-checkbox v-model="addTask.sendMessage" true-label="1" false-label="2"></el-checkbox>
                    </el-form-item>
                    <el-form-item label="任务描述">
                      <el-input type="textarea" :rows="3" v-model="addTask.repEventRp" style="width:420px"></el-input>
                    </el-form-item>
                  </el-form>
                </el-row>
                <div slot="footer">
                  <el-button size="mini" @click="dialogTask=false">关闭</el-button>
                  <el-button size="mini" type="primary" @click="saveTask">保存</el-button>
                </div>
              </el-dialog>
          </el-row>
          <el-table :data="taskData.records" @row-dblclick="taskDataDblclick" :row-class-name="tableRowShowInput" ref="task" style="width: 100%" height="396px" highlight-current-row size="mini">
            <el-table-column align="center" prop="repState" label="任务状态"></el-table-column>
            <el-table-column align="center" prop="repTypeRp" label="任务类型"></el-table-column>
            <el-table-column align="center" prop="repReportingTime" label="登记时间" min-width="100px" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="repEventRp" label="任务描述" min-width="140px" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="repToReceive" label="受理时间" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="repImgNum" label="图片数量"></el-table-column>
            <el-table-column align="center" prop="repFinishTime" label="完成时间" show-overflow-tooltip></el-table-column>
            <el-table-column align="center" prop="timeConsuming" label="总耗时"></el-table-column>
          </el-table>
          <el-pagination
            background
            :page-size="taskLim"
            align="center"
            :current-page="taskCur"
            layout="prev, pager, next"
            @current-change="taskCurChange"
            :total="taskData.total"
            style="margin-top: 10px">
          </el-pagination>
          <el-dialog title="任务信息" :visible.sync="dialogTaskDbl" :close-on-click-modal="false" width="710px" append-to-body>
            <el-button size="mini" type="primary" @click="taskUpload">附件</el-button>
            <el-form inline size="mini" label-width="70px">
              <el-form-item label="办公室名称">
                <el-input :value="homeRow.hsAddCommunity" disabled style="width:220px"></el-input>
              </el-form-item>
              <el-form-item label="任务类型">
                <el-input :value="taskRow.repTypeRp" disabled style="width:140px"></el-input>
                <el-input value="项目任务" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="任务状态">
                <el-input :value="taskRow.repState" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="负责人">
                <el-input :value="taskRow.repairman" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="领取时间">
                <el-input :value="taskRow.repToReceive" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="客户姓名">
                <el-input :value="taskRow.repContacts" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="客户电话">
                <el-input :value="taskRow.repContactsPhone" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="期望时间">
                <el-input :value="taskRow.repHopeTime" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="登记人">
                <el-input :value="taskRow.username" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="登记时间">
                <el-input :value="taskRow.repReportingTime" disabled style="width:140px"></el-input>
              </el-form-item>
              <el-form-item label="任务描述">
                <el-input :value="taskRow.repEventRp" type="textarea" disabled style="width:580px"></el-input>
              </el-form-item>
            </el-form>
            <el-table :data="taskFollowData" @row-dblclick="taskFollDblclick" height="200px" highlight-current-row size="mini">
              <el-table-column align="center" prop="proTime" label="进展时间"></el-table-column>
              <el-table-column align="center" prop="suStaffName" label="负责人"></el-table-column>
              <el-table-column align="center" prop="proState" label="进展状态"></el-table-column>
              <el-table-column align="center" prop="proRemark" label="备注"></el-table-column>
            </el-table>
            <el-dialog width="500px" title="进展详情" :visible.sync="dialogTaskFol" :close-on-click-modal="false" append-to-body>
              <table class="tableSp" style="margin:auto;width:450px">
                <tbody>
                  <tr>
                    <td >进展时间</td>
                    <td colspan="3" style="color:blue;">{{taskFollowRow.proTime}}</td>
                  </tr>
                  <tr>
                    <td >处理人</td>
                    <td style="color:blue;">{{taskFollowRow.suStaffName}}</td>
                    <td >进展状态</td>
                    <td style="color:blue">{{taskFollowRow.proState}}</td>
                  </tr>
                  <tr>
                    <td >备注</td>
                    <td colspan="3" style="color:blue;">{{taskFollowRow.proRemark}}</td>
                  </tr>
                </tbody>
              </table>
            </el-dialog>
            <div slot="footer" align="center">
              <el-button type="primary" @click="taskPrv">上一条</el-button>
              <el-button type="primary" @click="taskNext">下一条</el-button>
            </div>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="收支记录" name="budget" v-if="authe.Hotel_RegionalDivision_OfficeArea_IncomeExpenditureRecords">
          <el-form size="mini" inline>
            <el-form-item label="记账日期">
              <el-date-picker
                v-model="Time"
                @change="getRecords(1)"
                type="daterange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
              </el-date-picker>
              <!-- <el-date-picker v-model="commonQuery.startTime" @change="getRecords(1)" type="date" size="mini" placeholder="选择日期" style="width:150px" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>至
              <el-date-picker v-model="commonQuery.endTime" @change="getRecords(1)" type="date" size="mini" placeholder="选择日期" style="width:150px" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker> -->
            </el-form-item>
          </el-form>
          <el-table @row-dblclick="budgetDblclick" :data="budgetData.records" fit highlight-current-row style="width: 100%" height="396px" size="mini">
            <el-table-column prop="jfAuditState" label="财务状态" align="center"></el-table-column>
            <el-table-column prop="jfClosedWay" label="账户类型" min-width="80px" align="center" ></el-table-column>
            <el-table-column prop="jfNatureOfThe" label="收支性质" min-width="50px" align="center"></el-table-column>
            <el-table-column prop="jfAccountingSpecies" label="收支种类" min-width="80px" show-overflow-tooltip align="center" ></el-table-column>
            <el-table-column prop="jfSumMoney" label="收支金额" min-width="80px" align="center"></el-table-column>
            <el-table-column prop="handlersName" label="经手人" min-width="70px" align="center" ></el-table-column>
            <el-table-column prop="jfCheckInTime" label="登记日期" min-width="100px" show-overflow-tooltip align="center" ></el-table-column>
            <el-table-column prop="cashierPeopleName" label="记账人" width="80px" align="center"></el-table-column>
            <el-table-column prop="reviewerName" label="审核人" width="80px" align="center"></el-table-column>
            <el-table-column prop="reviewOneName" label="复核人" width="80px" align="center"></el-table-column>
            <el-table-column prop="jfStrikeABalanceStatus" label="冲账状态" width="80px" align="center"></el-table-column>
          </el-table>
          <el-pagination
            background
            :page-size="commonQuery.limit"
            align="center"
            :current-page="commonQuery.cursor"
            layout="prev, pager, next"
            @current-change="budgetCurChange"
            :total="budgetData.total"
            style="margin-top: 10px">
          </el-pagination>
          <el-dialog width="900px" title="收支详情" :visible.sync="dialogBudget" :close-on-click-modal="false" append-to-body>
            <table class="tableSp" style="margin:auto;width:850px">
              <tbody>
                <tr>
                  <td >楼盘名称</td>
                  <td colspan="5" style="color:blue;">办公室--{{homeRow.addDoorplateno}}</td>
                </tr>
                <tr>
                  <td >财务状态</td>
                  <td style="color:blue;">{{budgetRow.jfAuditState}}</td>
                  <td >冲账状态</td>
                  <td style="color:blue">{{budgetRow.jfStrikeABalanceStatus}}</td>
                  <td >经手人</td>
                  <td style="color:blue">{{budgetRow.handlersName}}</td>
                </tr>
                <tr>
                  <td >归属类型</td>
                  <td style="color:blue;">{{budgetRow.jfTheOwnershipType}}</td>
                  <td >归属名称</td>
                  <td style="color:blue">{{budgetRow.jfBelongingToTheName}}</td>
                  <td >记账日期</td>
                  <td style="color:blue">{{budgetRow.jfCheckInTime}}</td>
                </tr>
                <tr>
                  <td >账户类型</td>
                  <td style="color:blue;" colspan="2">{{budgetRow.jfClosedWay}}</td>
                  <td >账户名称</td>
                  <td style="color:blue" colspan="2">{{budgetRow.faUserName}}</td>
                </tr>
                <tr>
                  <td >账户号码</td>
                  <td style="color:blue;" colspan="2">{{budgetRow.faAccount}}</td>
                  <td >账户归属</td>
                  <td style="color:blue" colspan="2">{{budgetRow.faBelonging}}</td>
                </tr>
                <tr>
                  <td >收支性质</td>
                  <td style="color:blue;">{{budgetRow.jfNatureOfThe}}</td>
                  <td >收支类别</td>
                  <td style="color:blue">{{budgetRow.jfBigType}}</td>
                  <td >收支种类</td>
                  <td style="color:blue">{{budgetRow.jfAccountingSpecies}}</td>
                </tr>
                <tr>
                  <td >收支金额</td>
                  <td style="color:blue;">{{budgetRow.jfSumMoney}}</td>
                  <td >归属周期</td>
                  <td style="color:blue" colspan="3">{{budgetRow.jfStartCycle}}到{{budgetRow.jfEndCycle}}</td>
                </tr>
                <tr>
                  <td >票据编号</td>
                  <td style="color:blue;">{{budgetRow.jfTicketNumber}}</td>
                  <td >流水号</td>
                  <td style="color:blue">{{budgetRow.jfFinancialCoding}}</td>
                  <td >凭证号</td>
                  <td style="color:blue">{{budgetRow.jfCertificateNumber}}</td>
                </tr>
                <tr>
                  <td>收支原因</td>
                  <td colspan="5" style="color:blue;">{{budgetRow.jfFinanNote}}</td>
                </tr>
                <tr>
                  <td>操作记录</td>
                  <td colspan="5" style="color:blue;">{{budgetRow.jfOperationRecords}}</td>
                </tr>
                <tr>
                  <td >记账人</td>
                  <td style="color:blue;">{{budgetRow.cashierPeopleName}}</td>
                  <td >审核人</td>
                  <td style="color:blue">{{budgetRow.reviewerName}}</td>
                  <td >复核人</td>
                  <td style="color:blue">{{budgetRow.reviewOneName}}</td>
                </tr>
              </tbody>
            </table>
          </el-dialog>
        </el-tab-pane>
        <el-tab-pane label="区域管理" name="fourth" v-if="authe.Hotel_RegionalDivision_OfficeArea_Region">
          <el-button @click="addAreaClick" icon="el-icon-edit" size="mini">新增区域</el-button>
          <el-button @click="monitoringClick" icon="el-icon-edit" size="mini">监测设备</el-button>
          <el-button @click="publicClick" icon="el-icon-edit" size="mini">公共设备</el-button>
          <el-button @click="locationClick" icon="el-icon-edit" size="mini">位置设备</el-button>
          <el-table
                  :data="areaData"
                  highlight-current-row
                  @current-change="currentChangeArea"
                  @row-dblclick="roDblclickArea"
                  height="380px"
                  style="width:100%;" >
            <el-table-column prop="jrName" label="区域名称" min-width="30px"></el-table-column>
            <el-table-column prop="monitorDevices" label="监测设备" min-width="40px"></el-table-column>
            <el-table-column prop="publicDevices" label="公共设备" min-width="40px"></el-table-column>
            <el-table-column prop="positionDevices" label="位置设备" min-width="40px"></el-table-column>
            <el-table-column label="位置信息" min-width="40px">
              <template slot-scope="scope">
                <el-button @click="locationDialogClick(scope.row)" size="mini">位置</el-button>
              </template>
            </el-table-column>
            <el-table-column label="刪除" min-width="40px" align="center">
              <template slot-scope="scope">
                <el-button @click="deleteArea(scope.row)" size="mini" type="danger">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="智能设备" name="equipment" v-if="authe.Hotel_RegionalDivision_OfficeArea_Equipment">
          <el-button @click="equipmentOperation" icon="el-icon-edit" style="background-color: transparent!important;" size="mini">操作设备</el-button>
          <el-table :data="dataRoomEquipment" highlight-current-row @row-click="currentChangeEquipment" @row-dblclick="equipmentOperation" height="380px" style="width=100%;" >
            <el-table-column prop="idftName" label="设备类型" min-width="30px"></el-table-column>
            <el-table-column prop="idstName" label="设备型号" min-width="40px"></el-table-column>
            <el-table-column prop="devNickname" label="设备名称" min-width="40px"></el-table-column>
            <el-table-column prop="stateInformation" label="设备状态" :key="equipmentStateKey" min-width="100px"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="情景模式" name="third" v-if="authe.Hotel_RegionalDivision_OfficeArea_Scene">
          <el-form inline label-width="70px">
            <el-form-item label="情景模式">
              <el-select v-model="spdId" @change="getScenarioMode" clearable size="mini" style="width:110px">
                <el-option v-for="(value,i) in this.dataScenarioMode" :key="i" :label="value.spdDescribe" :value="value.spdId"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="启用智控">
              <el-select v-model="IntelligenceControl" clearable size="mini" style="width:110px">
                <el-option label="启用" value="1"></el-option>
                <el-option label="不启用" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-table
                  :data="dataRoomMode"
                  height="345px"
                  style="width:100%; margin-top:10px">
            <el-table-column prop="devNickname" label="设备列表" min-width="100px"></el-table-column>
            <el-table-column label="是否启用" min-width="100px">
              <template slot-scope="scope">
                <el-switch class="switchStyle" v-model="scope.row.isEnable" :active-value="1" :inactive-value="0" active-text="启用" inactive-text=不启用 active-color="#00A854" inactive-color="#F04134"></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="开关状态" min-width="100px">
              <template slot-scope="scope">
                <el-switch class="switchStyle" v-model="scope.row.jsroImsState.state" active-value="开" inactive-value="关" :disabled="scope.row.isEnable == 0" active-text="开" inactive-text="关" active-color="#00A854" inactive-color="#F04134"></el-switch>
              </template>
            </el-table-column>
            <el-table-column label="详细设置" min-width="100px">
              <template slot-scope="scope">
                <el-button @click="otherSettings(scope.row)"
                           v-show="secondId.airConditioner.type.indexOf(scope.row.devSecondType) > -1 || secondId.coolingAndHeatingLamp.type.indexOf(scope.row.devSecondType) > -1 || secondId.adjustingLamp.type.indexOf(scope.row.devSecondType) > -1"
                           :disabled="scope.row.isEnable == 0"
                           size="mini">详细设置</el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <!-- 房间选择 -->
    <select-address
      v-if="costRelationVisible"
      :isDialogShow="costRelationVisible"
      :isTitle="costRelationTitle"
      @close="selectRoomDblclick"
    >
    </select-address>
    <el-dialog width="680px" title="查看图片" v-loading="loading" :visible.sync="dialogSeeImgPath"  @close='dialogImgPathClose' :close-on-click-modal="false" append-to-body>
      <div style="width:100%;height:400px;overflow-y:auto">
        <el-image v-for="(item, index) in photoList" :key="index" style="width: 150px; height: 150px;border-radius:5px;margin:5px 5px" :src="item.url" :preview-src-list="photoList"></el-image>
      </div>
    </el-dialog>
    <el-dialog title="控制设备" :visible.sync="dialogControlEquipment" @close="assignmentControlEquipment" v-loading="loading" :close-on-click-modal="false" width="400px">
      <el-form v-if="secondId.airConditioner.type.indexOf(rowButton.devSecondType) > -1">
        <el-form-item>
          温度：<el-input v-model="secondId.airConditioner.mode.temperature" size="mini" style="width:88%"></el-input>
        </el-form-item>
        <el-form-item>
          模式：<el-select v-model="secondId.airConditioner.mode.pattern" size="mini" style="width:88%">
            <el-option label="自动" value="00"></el-option>
            <el-option label="制冷" value="01"></el-option>
            <el-option label="除湿" value="02"></el-option>
            <el-option label="送风" value="03"></el-option>
            <el-option label="除湿" value="04"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          风速：<el-select v-model="secondId.airConditioner.mode.windSpeed" size="mini" style="width:88%">
            <el-option label="自动" value="00"></el-option>
            <el-option label="高" value="03"></el-option>
            <el-option label="中" value="02"></el-option>
            <el-option label="低" value="01"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-form v-else-if="secondId.coolingAndHeatingLamp.type.indexOf(rowButton.devSecondType) > -1">
        <el-form-item>
          <el-row>
            <el-col :span="4">亮度：</el-col>
            <el-col :span="20">
              <el-slider v-model="secondId.coolingAndHeatingLamp.mode.brightness" :step="5"></el-slider>
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item>
          <el-row>
            <el-col :span="4">色温：</el-col>
            <el-col :span="20">
              <el-slider v-model="secondId.coolingAndHeatingLamp.mode.colorTemperature" :step="5"></el-slider>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <el-form v-else-if="secondId.adjustingLamp.type.indexOf(rowButton.devSecondType) > -1">
        <el-form-item>
          <el-row>
            <el-col :span="4">亮度：</el-col>
            <el-col :span="20">
              <el-slider v-model="secondId.adjustingLamp.mode.brightness" :step="5"></el-slider>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
    </el-dialog>
      <el-dialog title="选择操作" :visible.sync="dialogCaoZuoSheBei" width="710px" :close-on-click-modal="false" >
        <!-- 开关  -->
          <el-form v-if="this.panelForm.switch.indexOf(this.panelForm.devSecondType)>-1">
            <el-button type="primary" @click="openRoad">打开{{changestatus.csRoad}}</el-button>
            <el-button type="primary" @click="closeRoad">关闭{{changestatus.csRoad}}</el-button>
          </el-form>
        <!-- 插座 -->
          <el-form v-else-if="this.panelForm.socket.indexOf(this.panelForm.devSecondType)>-1">
            <el-form-item style="margin:15px 0">
            <el-button @click="openLight" type="primary">通&nbsp; 电</el-button>
            <el-button @click="closeLight" type="primary">断 &nbsp;电</el-button>
            <!-- <el-button type="primary">过热警报开</el-button>
            <el-button type="primary">过热警报关</el-button>
            <el-button type="primary" @click="dialogWenDuSheZhi=true">设置警报温度</el-button>
            </el-form-item>
            <el-form-item>
            <el-button type="primary" @click="dialogChaZuo=true">设置指示灯亮度</el-button>&nbsp;&nbsp;
            <el-button type="primary">同步时钟</el-button>
            <el-button type="primary">设置定时</el-button>
            <el-button type="primary">恢复出厂设置</el-button>
            <el-button type="primary" @click="dialogShangDian=true">上电默认设置</el-button>-->
            </el-form-item>
            <el-dialog title="温度设置" :visible.sync="dialogWenDuSheZhi" :close-on-click-modal="false" width="30%" center append-to-body>
              温度：<el-input style="width:150px" size="mini"></el-input>
            </el-dialog>
            <el-dialog title="插座指示灯亮度设置" :visible.sync="dialogChaZuo" :close-on-click-modal="false" width="30%" center append-to-body>
              亮度：<el-input style="width:150px"></el-input>
            </el-dialog>
            <el-dialog title="插座上电默认设置" :visible.sync="dialogShangDian" :close-on-click-modal="false" width="30%" center append-to-body>
              选项：<el-select v-model="value10" placeholder="请选择" size="mini">
                      <el-option label="开" value="0"></el-option>
                      <el-option label="关" value="1"></el-option>
                      <el-option label="记忆" value="2"></el-option>
                    </el-select>
            </el-dialog>
          </el-form>
          <el-form v-else-if="this.panelForm.socket2.indexOf(this.panelForm.devSecondType)>-1">
            <el-button @click="openLight" type="primary" >通电</el-button>
            <el-button type="primary" >断电</el-button>
          </el-form>
        <!-- 门锁 -->
          <!-- <el-form v-else-if="this.panelForm.doorLock.indexOf(this.panelForm.devSecondType)>-1">
            <el-button type="primary" @click="dialogKaiSuo=true">获取开锁记录</el-button>
            <el-dialog title="开锁记录" :visible.sync="dialogKaiSuo" :close-on-click-modal="false" width="600px" append-to-body>
              <el-table :data="kaisuoList1" style="width=100%;" highlight-current-row>
                <el-table-column prop="idftName" label="人员" min-width="50px"></el-table-column>
                <el-table-column prop="idstName" label="设备名称" min-width="80px"></el-table-column>
                <el-table-column prop="deviceAddress" label="设备安装地址" min-width="80px"></el-table-column>
                <el-table-column prop="devSn" label="上报信息类型" min-width="80px"></el-table-column>
                <el-table-column prop="" label="时间" min-width="80px"></el-table-column>
              </el-table>
            </el-dialog>
          </el-form> -->
        <!-- 刷卡门锁 -->
          <!-- <el-form v-else-if="this.panelForm.doorcard.indexOf(this.panelForm.devSecondType)>-1">
            <el-button type="primary">远程开锁</el-button>
            <el-button type="primary" @click="dialogYuanCheng=true">远程开锁记录</el-button>
              <el-dialog title="开锁记录" :visible.sync="dialogYuanCheng" :close-on-click-modal="false" width="600px" append-to-body>
                <el-table :data="kaisuoList2" highlight-current-row>
                  <el-table-column prop="idftName" label="人员" min-width="60px"></el-table-column>
                  <el-table-column prop="idstName" label="设备名称" min-width="80px"></el-table-column>
                  <el-table-column prop="deviceAddress" label="设备安装地址" min-width="100px"></el-table-column>
                  <el-table-column prop="devSn" label="开锁类型" min-width="80px"></el-table-column>
                  <el-table-column prop="devSn" label="开锁结果" min-width="80px"></el-table-column>
                  <el-table-column prop="" label="开锁时间" min-width="80px"></el-table-column>
                </el-table>
            </el-dialog>
          </el-form> -->
        <!-- 密码门锁 -->
          <!-- <el-form v-else-if="this.panelForm.doorPsd.indexOf(this.panelForm.devSecondType)>-1">
              <el-form-item style="margin-bottom:15px">
            <el-button type="primary">远程开锁</el-button>
            <el-button type="primary">添加密码</el-button>
            <el-button type="primary">启用密码</el-button>
            <el-button type="primary">禁用密码</el-button>
            <el-button type="primary">清空密码</el-button>
            <el-button type="primary" @click="dialogKaiSuo2=true" >获取开锁记录</el-button>
            </el-form-item>
            <el-button type="primary">添加IC卡</el-button>
            <el-button type="primary">启用IC卡</el-button>
            <el-button type="primary">禁用IC卡</el-button>
            <el-button type="primary">清空IC卡</el-button>
            <el-button type="primary">清空IC卡和密码</el-button>
            <el-dialog title="开锁记录" :visible.sync="dialogKaiSuo2" :close-on-click-modal="false" width="600px" append-to-body>
              <el-table :data="kaisuoList1" highlight-current-row>
                <el-table-column prop="idftName" label="人员" min-width="60px"></el-table-column>
                <el-table-column prop="idstName" label="设备名称" min-width="80px"></el-table-column>
                <el-table-column prop="deviceAddress" label="设备安装地址" min-width="100px"></el-table-column>
                <el-table-column prop="devSn" label="上报信息类型" min-width="80px"></el-table-column>
                <el-table-column prop="" label="时间" min-width="80px"></el-table-column>
              </el-table>
            </el-dialog>
          </el-form> -->
        <!-- 窗帘 -->
          <!-- <el-form v-else-if="this.panelForm.controller.indexOf(this.panelForm.devSecondType)>-1">
            <el-button type="primary" @click="openCurtain">开</el-button>
            <el-button type="primary" @click="closeCurtain">关</el-button>
            <el-button type="primary" v-if="this.panelForm.curtain.indexOf(this.panelForm.devSecondType)>-1" @click="spendCurtain">暂停</el-button>
          </el-form> -->
        <!-- 空调 -->
          <el-form v-else-if="this.panelForm.airConditioner.indexOf(this.panelForm.devSecondType)>-1">
            <el-form-item>
              <el-col :span="7" style="margin:70px 0 0 20px">
                <p style="font-size:60px;color:#3e8f3e;text-align:center;margin-bottom:10px">{{changestatus.devAirTemperature}}<span style="font-size:36px">℃</span></p>
                <el-button @click="airTemperatureMinus" size="mini" type="primary" icon="el-icon-minus" style="margin:0px 0 0 40px"></el-button>
                <el-button @click="airTemperatureAdd" size="mini" type="primary" icon="el-icon-plus" style="margin:0px 0 0 30px"></el-button>
              </el-col>
              <el-col :span="7" :offset="1" style="color:#3e8f3e">
                <p>开关:&nbsp;&nbsp;{{changestatus.devState}}</p>
                <p>室温:&nbsp;&nbsp;{{changestatus.nowStates.roomTemperature}}</p>
                <p>模式:&nbsp;&nbsp;{{changestatus.devAirPattern}}</p>
                <p>风速:&nbsp;&nbsp;{{changestatus.devAirWindSpeed}}</p>
                <p v-if="this.panelForm.airConditioner1.indexOf(this.panelForm.devSecondType)>-1">扫风:&nbsp;&nbsp;{{changestatus.devAirSweepingWind}}</p>
                <p v-if="this.panelForm.airConditioner3.indexOf(this.panelForm.devSecondType)>-1">设备温度:&nbsp;&nbsp;{{changestatus.devAirTemperature2}}</p>
              </el-col>
              <el-col :span="7" :offset="1" v-if="this.panelForm.airConditioner1.indexOf(this.panelForm.devSecondType)  >-1" style="color:#3e8f3e">
                <p>累计电量:&nbsp;&nbsp;{{changestatus.devAirElectricity}}</p>
                <p>功率:&nbsp;&nbsp;{{changestatus.devAirPower}}</p>
                <p>电压:&nbsp;&nbsp;{{changestatus.devAirVoltage}}</p>
                <p>电流:&nbsp;&nbsp;{{changestatus.devAirCurrent}}</p>
                <p>功率因数:&nbsp;&nbsp;{{changestatus.devAirFactors}}</p>
                <p v-if="this.panelForm.airConditioner3.indexOf(this.panelForm.devSecondType)>-1">上限温度:&nbsp;&nbsp;{{changestatus.devAirProtectTemperature}}</p>
              </el-col>
            </el-form-item>
            <el-form-item align="center">
              <el-button type="primary" style="width:100px" @click="openLight"> 开 </el-button>
              <el-button type="primary" style="width:100px" @click="closeLight"> 关 </el-button>
              <el-button type="primary"  @click="dialogMoShi=true" style="width:100px">模 式</el-button>
              <el-button type="primary" @click="dialogFengSu=true" style="width:100px">风 速</el-button>
              <el-button type="primary" v-if="this.panelForm.airConditioner2.indexOf(this.panelForm.devSecondType)>-1" @click="dialogSaoFeng=true" append-to-body style="width:100px">扫 风</el-button>
            </el-form-item >

          </el-form>
          <!-- <el-form v-else-if="this.panelForm.protection.indexOf(this.panelForm.devSecondType)>-1">
            <el-button type="primary">外出布防</el-button>
            <el-button type="primary">在家布防</el-button>
            <el-button type="primary">撤防</el-button>
          </el-form> -->
        <!-- 电箱 -->
          <!-- <el-form  v-else-if="this.panelForm.electricBox.indexOf(this.panelForm.devSecondType)>-1">
            <el-form-item style="margin-bottom:5px">
              <el-row>
              <el-col :span="4" style="background:#EDF4FF;height:90px;border-radius:10px;text-align:center">
                <p></p>
                <p>功率</p>
              </el-col>
              <el-col :span="4"  style="background:#EDF4FF;height:90px;border-radius:10px;text-align:center;margin:0px 5px">
                <p></p>
                <p>电流/mA</p>
              </el-col>
              <el-col :span="5" style="background:#EDF4FF;height:90px;border-radius:10px;text-align:center">
                <p></p>
                <p>电压/V</p>
              </el-col>
              <el-col :span="5" style="background:#EDF4FF;height:90px;border-radius:10px;text-align:center;margin:0px 5px">
                <p></p>
                <p>温度/℃</p>
              </el-col>
              <el-col :span="5" style="background:#EDF4FF;height:90px;border-radius:10px;text-align:center">
                <p></p>
                <p>总电量/Kw·H</p>
              </el-col>
              </el-row>
            </el-form-item>
            <el-form-item>
              <el-row>
              <el-col :span="4" style="background:#EDF4FF;height:90px;border-radius:10px;text-align:center">
                <p></p>
                <p>限定功率/W</p>
              </el-col>
              <el-col :span="5" style="background:#EDF4FF;height:90px;border-radius:10px;text-align:center;margin:0px 5px">
                <p></p>
                <p>限定电量/W·H</p>
              </el-col>
              <el-col :span="4" style="background:#EDF4FF;height:120px;border-radius:50%;text-align:center">
                <p>未知</p>
                <p>当前状态</p>
              </el-col>
              <el-col :span="5" style="background:#EDF4FF;height:90px;border-radius:10px;text-align:center;margin:0px 5px">
                <p></p>
                <p>限定温度/℃</p>
              </el-col>
              <el-col :span="5" style="background:#EDF4FF;height:90px;border-radius:10px;text-align:center">
                <p></p>
                <p>剩余电量/Kw·H</p>
              </el-col>
              </el-row>
            </el-form-item>
            <el-form-item style="text-align:center">
              <p>更新时间：</p>
            </el-form-item>
          </el-form> -->
        <!-- 灯 -->
          <el-form v-else-if="this.panelForm.lightSwitch.indexOf(this.panelForm.devSecondType)>-1">
            <el-form-item>
              <el-button type="primary" @click="openLight">开灯</el-button>
              <el-button  type="primary" @click="closeLight">关灯</el-button>
            </el-form-item>
          </el-form>
          <el-form v-else-if="this.panelForm.lightSwitch2.indexOf(this.panelForm.devSecondType)>-1">
            <el-form-item>
              <el-row>
              <el-col :span="2" :offset="1">
              <el-button type="primary" @click="openLight">开灯</el-button>
              </el-col>
              <el-col :span="2" :offset="1">
              <el-button  type="primary" @click="closeLight">关灯</el-button>
              </el-col>
              <el-col :span="2" :offset="1">
                亮度:
              </el-col>
              <el-col :span="12">
              <el-slider v-model="changestatus.nowStates.brightness" @change="openLight"></el-slider>
              </el-col>
              </el-row>
            </el-form-item>
          </el-form>
          <el-form v-else-if="this.panelForm.lightSwitch3.indexOf(this.panelForm.devSecondType)>-1">
            <el-form-item>
                <el-row>
              <el-col :span="2" :offset="1">
              <el-button type="primary" @click="openLight">开灯</el-button>
              </el-col>
              <el-col :span="2" :offset="1">
              <el-button  type="primary" @click="closeLight">关灯</el-button>
              </el-col>
              <el-col :span="2" :offset="1">
                亮度:
              </el-col>
              <el-col :span="12">
              <el-slider v-model="changestatus.nowStates.brightness" @change="openLight"></el-slider>
              </el-col>
              </el-row>
            <el-row>
              <el-col :span="2" :offset="7">
                色温:
              </el-col>
              <el-col :span="12">
              <el-slider v-model="changestatus.nowStates.brightness" @change="openLight"></el-slider>
              </el-col>
              </el-row>
              </el-form-item>
          </el-form>
        <!-- 智能晾衣架-->
          <!-- <el-form v-else-if="this.panelForm.clothesHorse.indexOf(this.panelForm.devSecondType)>-1">
            <el-form-item>
              <el-col :span="12">
              电机：<el-radio-group v-model="radio">
                <el-radio :label="3">上行</el-radio>
                <el-radio :label="6">下行</el-radio>
                <el-radio :label="9">停止</el-radio>
              </el-radio-group>
              </el-col>
              <el-col :span="12">
              衣杆位置：<el-radio-group v-model="radio">
                <el-radio :label="3">其他</el-radio>
                <el-radio :label="6">上限</el-radio>
                <el-radio :label="9">下限</el-radio>
              </el-radio-group>
              </el-col>
            </el-form-item>
            <el-form-item style="margin:10px 0 0 0">
                <el-col :span="6">
              负离子：<el-switch
                    active-text="开"
                    inactive-text="关"
                    v-model="value10"
                    class="switchStyle"
                    active-color="#00A854"
                    inactive-color="#F04134">
                  </el-switch>
              </el-col>
              <el-col :span="6">
              烘 干：<el-switch
                    active-text="开"
                    inactive-text="关"
                    v-model="value10"
                    class="switchStyle"
                    active-color="#00A854"
                    inactive-color="#F04134">
                  </el-switch>
              </el-col>
              <el-col :span="8">
                <span>烘干时间：<el-input style="width:100px" size="mini"></el-input>分钟</span>
              </el-col>
              <el-col :span="4">
                <el-button size="mini">确定</el-button>
              </el-col>
            </el-form-item>
            <el-form-item  style="margin:5px 0">
              <el-col :span="6">
              语&nbsp;&nbsp; 音：<el-switch
                    active-text="开"
                    inactive-text="关"
                    v-model="value10"
                    class="switchStyle"
                    active-color="#00A854"
                    inactive-color="#F04134">
                  </el-switch>
              </el-col>
              <el-col :span="6">
              消 毒：<el-switch
                    active-text="开"
                    inactive-text="关"
                    v-model="value10"
                    class="switchStyle"
                    active-color="#00A854"
                    inactive-color="#F04134">
                  </el-switch>
              </el-col>
              <el-col :span="8">
                <span>消毒时间：<el-input style="width:100px" size="mini"></el-input>分钟</span>
              </el-col>
              <el-col :span="4">
                <el-button size="mini">确定</el-button>
              </el-col>
            </el-form-item>
            <el-form-item>
              <el-col :span="6">
              照&nbsp;&nbsp; 明：<el-switch
                    v-model="value10"
                    active-text="开"
                    inactive-text="关"
                    class="switchStyle"
                    active-color="#00A854"
                    inactive-color="#F04134">
                  </el-switch>
              </el-col>
              <el-col :span="6">
              风 干：<el-switch
                    v-model="value10"
                    active-text="开"
                    inactive-text="关"
                    class="switchStyle"
                    active-color="#00A854"
                    inactive-color="#F04134">
                  </el-switch>
              </el-col>
              <el-col :span="8">
                <span>风干时间：<el-input style="width:100px"  size="mini"></el-input>分钟</span>
              </el-col>
              <el-col :span="4">
                <el-button size="mini">确定</el-button>
              </el-col>
            </el-form-item>
          </el-form> -->
        <!-- 光照传感器 -->
          <el-form v-else-if="this.panelForm.lightSensor.indexOf(this.panelForm.devSecondType)>-1">
            <el-button type="primary" @click="dialogMinLumen=true">最小流明</el-button>
            <el-button type="primary" @click="dialogMaxLumen=true">最大流明</el-button>
            <el-button type="primary" @click="dialogSpaceLumen=true">间隔流明</el-button>
          </el-form>
        <!-- 人脸识别 -->
          <el-form v-else-if="this.panelForm.faceId.indexOf(this.panelForm.devFirstType)>-1">
            <el-button type="primary" @click="identify">识别记录</el-button>
            <!-- <el-button type="primary">删除</el-button> -->
            <el-button @click="restart" type="primary">重启</el-button>
            <el-button type="primary" @click="openDoor">一键开门</el-button>
            <el-button @click="magneticDoor" type="primary">绑定门磁</el-button>
            <el-button @click="deleteBind" type="primary">解除绑定</el-button>
            <el-dialog title="识别记录" @close="identifyClose" :visible.sync="dialogIdentify" :close-on-click-modal="false" width="800px" append-to-body>
              <el-form :model="faceForm">
                <el-form-item>
                  人员：<el-input @change="jftiPersonNameChange" v-model="faceForm.jftiPersonName" style="width:150px" size="mini" clearable></el-input>&nbsp;&nbsp;
                  时间：<el-date-picker
                    v-model="faceForm.time"
                    type="daterange"
                    align="right"
                    size="mini"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="jftiPersonNameChange"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerOptions">
                  </el-date-picker>
                </el-form-item>
              </el-form>
              <el-table :data="faceForm.faceIdList" highlight-current-row>
                <el-table-column prop="jftiPasernType" label="人员类型" min-width="80px"></el-table-column>
                <el-table-column prop="jftiPersonName" label="人员" min-width="80px"></el-table-column>
                <el-table-column prop="deviceName" label="设备名称" min-width="80px"></el-table-column>
                <el-table-column prop="jftiRecMode" label="识别类型" min-width="100px"></el-table-column>
                <el-table-column prop="jfriShowTime" label="识别记录时间" min-width="160px"></el-table-column>
                <el-table-column prop="jftiType" label="识别结果" min-width="80px"></el-table-column>
                <el-table-column label="抓拍照片" min-width="40px">
                    <template slot-scope="scope">
                      <img :src="scope.row.jftiPhotoUrl" alt="" style="width:40px" @click.self="imgShow(scope.row.jftiPhotoUrl)">
                    </template>
                </el-table-column>
              </el-table>
              <el-dialog :visible.sync="dialogImg" :close-on-click-modal="false" append-to-body>
                <img :src="dialogImageUrl" alt="" style="display:block;margin:0 auto;width:300px">
              </el-dialog>
              <el-pagination
                layout="prev, pager, next, total"
                :total="faceForm.total"
                :page-size="faceForm.limit"
                :page-count="faceForm.pages"
                :current-page="faceForm.cursor"
                @current-change="identifyCurrent"
                align="center"
                background>
              </el-pagination>
            </el-dialog>
            <el-dialog title="门磁绑定" :visible.sync="dialogMagnetic" :close-on-click-modal="false" width="800px" append-to-body>
              <el-table @row-click="magneticSel" @close="identifyClose" :data="faceForm.magneticList" highlight-current-row>
                <el-table-column prop="devNickname" label="设备名称" min-width="80px"></el-table-column>
                <el-table-column prop="devSn" label="设备SN" min-width="80px"></el-table-column>
                <el-table-column prop="hsAddCommunity" label="设备安装位置" min-width="100px"></el-table-column>n>
              </el-table>
              <div slot="footer">
                <el-button @click="dialogMagnetic = false">取 消</el-button>
                <el-button type="primary" @click="magneticSave">保 存</el-button>
              </div>
            </el-dialog>
          </el-form>
          <el-form v-else>
            <p style="text-align:center">没有相关操作</p>
          </el-form>
      </el-dialog>
      <el-dialog title="模式"  :visible.sync="dialogMoShi" :close-on-click-modal="false" width="200px" append-to-body>
        <el-button type="primary" @click="airSelf" style="width:100%;margin-left:-1px">自 动</el-button>
        <el-button type="primary" @click="airCold" style="width:100%;margin:2px 0 2px -1px">制 冷</el-button>
        <el-button type="primary" @click="airHot" style="width:100%;margin-left:-1px">制 热</el-button>
        <el-button type="primary" @click="airWet" style="width:100%;margin:2px 0 2px -1px">除 湿</el-button>
        <el-button type="primary" @click="airWind" style="width:100%;margin-left:-1px">送 风</el-button>
      </el-dialog>
      <el-dialog title="风速" :visible.sync="dialogFengSu" :close-on-click-modal="false" width="200px" append-to-body>
        <el-button type="primary" style="width:100%;margin-left:-1px" @click="airWindSpeedSelf">自动</el-button>
        <el-button type="primary" style="width:100%;margin:2px 0 2px -1px" @click="airWindSpeedHeight">高</el-button>
        <el-button type="primary" style="width:100%;margin-left:-1px" @click="airWindSpeedMiddle">中</el-button>
        <el-button type="primary" style="width:100%;margin:2px 0 0 -1px" @click="airWindSpeedLow">低</el-button>
      </el-dialog>
      <el-dialog title="扫风"  :visible.sync="dialogSaoFeng" :close-on-click-modal="false" width="200px" append-to-body>
        <el-button type="primary" style="width:100%;margin-left:-1px" @click="airSweepingWindSelf">自动</el-button>
        <el-button type="primary" style="width:100%;margin:2px 0 0 -1px" @click="airSweepingWindHand">手动</el-button>
      </el-dialog>
      <el-dialog title="操作设备" :visible.sync="dialogMinLumen" :close-on-click-modal="false" width="400px" append-to-body align="center">
          最小流明：<el-input type="number" v-model="changestatus.devUpperLimitLumen" size="mini" placeholder="请输入1-65535的整数" min="1" max="65535" clearable style="width:70%"></el-input>
          <el-button type="primary" size="mini" style="margin-top:5px" @click="minLumenChange">确定</el-button>
      </el-dialog>
      <el-dialog title="操作设备" :visible.sync="dialogMaxLumen" :close-on-click-modal="false" width="300px" append-to-body align="center">
          最大流明：<el-input type="number" v-model="changestatus.devLowerLimitLumen" size="mini" placeholder="请输入1-65535的整数" min="1" max="65535" clearable style="width:70%"></el-input>
          <el-button type="primary" size="mini" style="margin-top:5px" @click="maxLumenChange">确定</el-button>
      </el-dialog>
      <el-dialog title="操作设备" :visible.sync="dialogSpaceLumen" :close-on-click-modal="false" width="300px" append-to-body align="center">
        间隔流明：<el-input type="number" v-model="changestatus.devLumenSpacing" size="mini" placeholder="请输入1-65535的整数" min="1" max="65535" clearable style="width:70%"></el-input>
          <el-button type="primary" size="mini" style="margin-top:5px" @click="spaceLumenChange">确定</el-button>
      </el-dialog>

    <!-- 区域管理相关 -->
    <el-dialog :title="addAreaTitle" :visible.sync="addArea" v-loading="loading" @close="closeAddArea" :close-on-click-modal="false" width="400px">
      <el-form>
        <el-form-item label="区域名称" label-width="75px">
          <el-input v-model="areaName" size="mini"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addArea = false">取 消</el-button>
        <el-button type="primary" v-if="addAreaState" @click="addAreaPut">保 存</el-button>
        <el-button type="primary" v-else @click="addAreaPost">保 存</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="this.title" :visible.sync="monitoringBox" @close="cleanMonitoring" :close-on-click-modal="false" width="900px">
      <el-row class="transferStyle"><!--穿梭框-->
        <el-col>
          <el-transfer v-model="shuttleBox.jourDeviceId" :props="{key: 'jourDeviceId',label: 'devNickname'}" :data="monitoringBoxData"></el-transfer>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button @click="monitoringBox = false">取 消</el-button>
        <el-button type="primary" @click="monitoringBoxSave">保 存</el-button>
      </div>
    </el-dialog>

    <!--  位置信息弹出框-->
    <el-dialog title="位置信息" :visible.sync="locationDialog" v-loading="loading" @close="closeAddArea" :close-on-click-modal="false" width="900px">
      <el-button @click="positionClick" icon="el-icon-edit" size="mini">添加位置</el-button>
      <el-button @click="equipmentClick" icon="el-icon-edit" size="mini">设备绑定</el-button>
      <el-button @click="personnelClick" icon="el-icon-edit" size="mini">绑定人员</el-button>
      <el-button @click="unbundlingClick" icon="el-icon-edit" size="mini">解绑人员</el-button>
      <el-button @click="banEquipmentClick" icon="el-icon-edit" size="mini">禁止设备</el-button>
      <el-button @click="banBlackoutClick" icon="el-icon-edit" size="mini">禁止断电</el-button>
      <el-table
              :data="locationData"
              highlight-current-row
              @current-change="currentChangePosition"
              @row-dblclick="roDblclickPosition"
              height="380px"
              style="width:100%;" >
        <el-table-column prop="jpPositionName" label="位置名称" min-width="40px"></el-table-column>
        <el-table-column prop="personalDevices" label="个人设备" min-width="40px"></el-table-column>
        <el-table-column prop="suStaffName" label="人员信息" min-width="40px"></el-table-column>
        <el-table-column label="删除" min-width="40px">
          <template slot-scope="scope">
            <el-button @click="deletePosition(scope.row)" size="mini" type="danger">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!--  添加位置弹出框-->
    <el-dialog :title="addPositionTitle" :visible.sync="addPosition" v-loading="loading" @close="cleanLocation" :close-on-click-modal="false" width="400px">
      <el-form>
        <el-form-item label="位置名称" label-width="75px">
          <el-input v-model="jpPositionName" size="mini"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addPosition = false">取 消</el-button>
        <el-button type="primary" v-if="addPositionState" @click="addPositionPut">保 存</el-button>
        <el-button type="primary" v-else @click="addPositionPost">保 存</el-button>
      </div>
    </el-dialog>
    <el-dialog title="选择绑定设备" :visible.sync="equipmentBox" @close="cleanMonitoring" :close-on-click-modal="false" width="900px">
      <el-row class="transferStyle"><!--穿梭框-->
        <el-col>
          <el-transfer v-model="equipmentShuttle.jourDeviceId" :props="{key: 'jourDeviceId',label: 'devNickname'}" :data="equipmentBoxData"></el-transfer>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button @click="equipmentBox = false">取 消</el-button>
        <el-button type="primary" @click="equipmentBoxSave">保 存</el-button>
      </div>
    </el-dialog>
    <!--  绑定人员弹出框-->
    <el-dialog title="选择人员" :visible.sync="addPersonnel" v-loading="loading" @close="closeBindingPersonnel" :close-on-click-modal="false" width="400px">
      <el-form>
        <el-form-item label="绑定人员:" label-width="75px">
          <el-cascader
                  v-model="bindingPersonnel"
                  size="mini"
                  :options="options"
                  :props="{ expandTrigger: 'hover' }"></el-cascader>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="addPersonnel = false">取 消</el-button>
        <el-button type="primary" v-if="addPersonnelState" @click="addPositionPut">保 存</el-button>
        <el-button type="primary" v-else @click="addPersonnelSave">保 存</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="banTitle" :visible.sync="banEquipmentBox" @close="cleanBanEquipment" :close-on-click-modal="false" width="900px">
      <el-row class="transferStyle"><!--穿梭框-->
        <el-col>
          <el-transfer v-model="banEquipmentShuttle.jourDeviceId" :props="{key: 'jourDeviceId',label: 'devNickname'}" :data="banEquipmentBoxData"></el-transfer>
        </el-col>
      </el-row>
      <div slot="footer">
        <el-button @click="banEquipmentBox = false">取 消</el-button>
        <el-button type="primary" v-if="banState" @click="banBlackoutBoxSave">保 存</el-button>
        <el-button type="primary" v-else @click="banEquipmentBoxSave">保 存</el-button>
      </div>
    </el-dialog>

    <!-- 图片上传组件 -->
    <common-upload ref="upload" @listenEvent="dialogImgPathClose" :dialog="dialogImgPath" :save="needSavePhoto" :photoid="photoTableId" :photorandom="photoRandom" :phototype="photoTableType"></common-upload>

  </div>
</template>

<script>
  import moment from 'moment';
  import { mapState } from 'vuex'
  //  引入上传图片组件
  import commonUpload from '../../components/common-upload'
  import selectAddress from '../../components/selectAddress/selectAddress'
  export default {
     components: {
      'common-upload':commonUpload,
      'select-address':selectAddress
    },
    name:'publicArea',
    data(){
      return{
        rules:{
          saType:[{ required: true, message: '资产所属不能为空', trigger: 'change'}],
          saUse:[{ required: true, message: '使用情况不能为空', trigger: 'change'}],
          saStatus:[{ required: true, message: '资产状态不能为空', trigger: 'change'}],
          saClassify:[{ required: true, message: '资产类型不能为空', trigger: 'change'}],
          saName:[{ required: true, message: '名称不能为空', trigger: 'change'}],
          saPrice:[{ required: true, message: '价格不能为空', trigger: 'blur'}],
        },
        loading:false,
        tableHeight:window.innerHeight - 240,
        dialogWarehouse:false,
        wareTitle:'',
        homeParms:{
          limit:10,
          cursor:1,
          hsAddCommunity:'',
          hsHouseNote:'',
          hsState:'正常',
          hsAddCity:'办公室',
        },
        homeData:{},//首页分页表格数据
        homeRow:{},//首页表格双击行数据
        activeName:'',
        dialogInfo:false,//首页表格双击弹框
        addData:{//办公室添加修改数据
          hsAddCommunity:'',
          hsHouseNote:'',
          hsState:'正常',
          hsAddCity: '办公室',
        },
      //选择房间数据
        costRelationTitle:'',
        costRelationVisible:false,
        selectedHouse:{},
        roomAddress:'',
        roomHsId:'',
      //双击弹框内数据
        //资产数据
          assetForm: {
            topShow: false,
            dialogElectric: false,
            limit: 10,
            cursor: 1,
            total: 0,
            pages: 0,
            electricList: [],
            dialogFollowup:false,
            followupRow:'',
            homeRow:'',
            homeSel:[],
            params: {
              saType: '',//资产所属
              saUse: '',//使用情况
              saStatus: '',//资产状态
              saNumber: '',//资产编号
              saClassify: '',//资产类型
              saName: '',//资产名称
              saBrand: '',//品牌
              saModel: '',//型号
            }
          },
          assFolLimit :5,
          assFolCur:1,
          assFollowData:{},
          addAssetsForm:{
            dialogAddElectric:false,
            assets: {
              saType: '', //公司
              saClassify: '', //办公设备
              saUse: '', //未使用
              saStatus: '', //正常
              saName: '', //打印机
              saBrand: '', //A牌
              saModel: '', //型号
              saPrice: '', //价格
              saRemarks: '', //00备注00
              assetsBelong:'',//资产所属
              saSupplierName:'',//供应商名称
              saSupplier:'',//供应商Id
            },
            dialogAddElectricTitle:'',
            onlyFixAssets:'1',
            assetsType:[],
            saTypeList:['','公司','业主','租赁'],
            saUseList:['','未使用','使用中','已报废'],
            saStatusList:['','正常','损坏','丢失','注销'],
            limit: 10,
            cursor: 1,
            total: 0,
            pages: 0,
            limit2:10,
            cursor2:1,
            total2:0,
            pages2:0,
            dialogHouseList:false,
            number: '',
            assetsPARMS:[],
          },
          moveOutForm:{
            address:'',
            moveReason:'',
            handId:'',
            saAgent:'',
            dialogMoveOut:false
          },
          printCodeForm:{
            dialogprintCode:false,
            urlList:[],
            selList:[],
          },
        //审批
          approvalParams:{
            limit:10,
            cursor:1,
            eaStoreId:''
          },
          dialogAppro:false,
          appList:[],
          approvalData:{},
          eaEventHandler:[],
          addAppro:{
            eaAmountInvolved: '',
            eaEventType: '',
            eaProcessId:'',
            eaApprovalNumber: '',
            eaEventContent: '',
            eaBankAccountDescription: '',//账号描述
            eaBankAccountNumber: '',
            eaBankName: '',
            eaBankUsername: '',
            eaSubordinateBranch:''
          },
          demoOpt :[],
          isArea:false,
          msgChecked:false,
          firstCheck:false,
          scBank:'',
          scAccName:'',
          bankCur:1,
          bankLim:10,
          bankAccData:{},
          selBankAccDia:false,
          approvalRow:{},
          eaTreatmentOpinion:[],
          dialogAppFol:false,
          appFollowRow:{},
          dialogAppDbl:false,
          dialogSeeImgPath:false,
          photoList:[],
        //任务
          dialogTask:false,
          taskLim:10,
          taskCur:1,
          taskData:{},
          taskRow:{},
          repRepairPeopleId:'',
          addTask:{
            repEventRp:'',
            repContactsPhone:'',
            repContacts:'',
            repHopeTime:'尽快',
            sendMessage:'1',
            repTaskTime:'',
            repGrade:'1'
          },
          dialogTaskFol:false,
          taskFollowRow:{},
          taskFollowData:[],
          timeList:[ '尽快', '今天', '明天', '后天', '本周末', '电话联系' ],
          dialogTaskDbl:false,
        //收支
          Time:null,
          commonQuery: {//首页数据
            limit: 10, // 每页条数
            cursor: 1, // 当前页数
            hsId:'',
            parameter: 1, // 登记（1）/记账（2）日期
            sort: 2, // 正序（1）/ 倒叙（2）
            startTime:'', // 筛选开始时间
            endTime:''// 筛选结束时间
          },
          budgetData:{},
          budgetRow:{},
          dialogBudget:false,
        //设备控制弹窗
          loading: 0,
          labelWidth: '45px',
          equipmentStateKey: 0,
          row: '',
          value10:'',
          rowButton: '',
          saveButtonState: 'first',
          equipmentRow: '',
        //设备控制弹窗
          dialogImg:false,
          dialogIdentify:false,
          dialogMagnetic:false,
          dialogMoShi:false,
          dialogFengSu:false,
          dialogSaoFeng:false,
          dialogMinLumen:false,
          dialogMaxLumen:false,
          dialogSpaceLumen:false,
          dialogRoom: false,//添加修改办公室弹窗
          dialogRoomDetails: false,//双击教师详情弹窗
          dialogControlEquipment: false,//双击控制设备
          dialogCaoZuoSheBei:false,
          IntelligenceControl: '',
          room: {//添加修改办公室
            data: {
              hsAddCity: '办公室',
              hsAddCommunity: '',
              hsHouseNote: '',
              classroomCategory: '',
              hsNumber: '',
              houseNumber: '',
              hsDiscipline: '',

              time: '',
              building: '',
              jccId: '',
              hsId:'',
            },
            dataRoomType: [],//办公类别下拉表
            dataBuilding: [],//办公楼栋下拉表
            dataDoorplateno:[],//门牌号下拉菜单
            dataPersonCharge:[],//负责人菜单
          },
          tab: 'first',
          conditionHouse: {//数据分页查询
            params: {
              jccType: '办公室',
              jhcName: '',//名称
              jhcRemarks: '',//备注描述
              // keyNumber: '',//联系人
              // hsState: '正常',//办公室状态
              limit: 10,//每页的条数
              cursor: 1,//初始页
            },
            total: 0,//总条数
            pages: 0,//每页数据
          },
          dataList: [],//分页查询办公室数据
          dataRoomEquipment: [],//智能设备数据
          dataRoomMode: [],//情景模式数据
          spdId: '',
          panelForm:{//操作设备相关
            devSecondType:'',
            devFirstType:'',
            switch:['1'],//开关
            protection:['8'],//安防网关
            socket:['25'],//增强型带定时单路插座
            socket2:['37'],//2.4G插座
            doorLock:['3'],//门锁通用
            doorcard:['22','24'],//刷卡门锁
            doorPsd:['23','40'],//密码门锁
            curtain:['38'],//百分比窗帘
            controller:['6','38','41','42'],//控制器
            clothesHorse:['34'],//智能晾衣架
            lightSensor:['50'],//光照传感器
            lightSwitch:['48'],//灯=>开关
            lightSwitch2:['36'],//灯=>亮度
            lightSwitch3:['31','44'],//灯=>色温
            electricBox:['16'],//电箱
            airConditioner:['4','43','49'],//空调=>中央空调
            airConditioner1:['4','43'],//空调=>漏电保护开关
            airConditioner3:['43'],//空调=>漏电保护开关+设备温度
            airConditioner2:['4'],//空调
            faceId:['24'],//人脸识别
          },
          faceForm:{//人脸开门
            faceIdList:[],
            magneticList:[],
            cursor:1,
            total:0,
            pages:0,
            limit:5,
            endTime:'',
            startTime:'',
            id:'',
            jftiPersonName:'',
            dfdnDoorSn:'',
            jftiUserId:'',

            time:'',
            // startTime:'',
            // endTime:''
          },
          pickerOptions: {
            shortcuts: [{
              text: '最近一周',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit('pick', [start, end]);
              }
            }, {
              text: '最近一个月',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit('pick', [start, end]);
              }
            }, {
              text: '最近三个月',
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit('pick', [start, end]);
              }
            }]
          },
          changestatus:{//修改设备状态数据
            nowStates:{},
              devAirPattern:'',
              devAirSweepingWind:'',
              devAirTemperature:'',
              devAirWindSpeed:'',
              devBrightness:'',
              devColorTemperature:'',
              devId:'',
              devLowerLimitLumen:'',
              devLumenSpacing:'',
              devNumber:'',
              devPercentage:'',
              devState:'',
              devUpperLimitLumen:'',
              temperature:'',
              devAirFactors:'',
              devAirCurrent:'',
              devAirVoltage:'',
              devAirPower:'',
              devAirElectricity:'',
              devAirTemperature2:'',
              devAirProtectTemperature:'',
              csRoad:'',
              csRoad2:'',
              csRoad3:'',
          },
          dataScenarioMode: [],//情景设置数据
          dataAddressMode: [],//情景地址数据
          dataSwitchMode: [],//情景开关状态
          secondId: {//情景模式详细设置
            airConditioner: {//空调
              type: ['4','42'],
              mode: {
                temperature: '',
                pattern: '',
                windSpeed: '',
              }
            },
            coolingAndHeatingLamp: {//冷暖灯
              type: ['31','44','56'],
              mode: {
                brightness: 0,
                colorTemperature: 0,
              }
            },
            adjustingLamp: {//调节灯
              type: ['36'],
              mode: {
                brightness: 0,
              }
            },
          },

        //区域管理
          areaData: [], //区域管理列表信息
          addArea: false,//新增区域
          areaName: '',//区域名称
          monitoringBox: false,//监测设备穿梭框
          monitoringBoxData: [],//监测设备数据
          GiveData: [],//穿梭框赋值数组
          addAreaState: false,//保存按钮状态
          addAreaTitle: '',
          shuttleBox: {
            jourDeviceId: [],
          },
          rowArea: '',
          title: '',//穿梭框标题
          ggjrdDeviceType: 0,
          monitoringRelated: {
            title: '选择监测设备',
            jrdDeviceType: 1
          },
          publicRelated: {
            title: '选择公共设备',
            jrdDeviceType: 2
          },
          locationRelated: {
            title: '选择位置设备',
            jrdDeviceType: 3
          },

        //位置管理相关
          // qqqq
          locationDialog: false,
          locationData: [],//位置信息数据
          addPositionTitle: '',//添加位置标题
          addPosition: false,//添加位置弹出框
          addPositionState: false,//添加位置保存按钮状态
          jpPositionName: '',//位置名称
          rowPosition: '',
          equipmentRelated: {
            title: '选择绑定设备',
            jrdDeviceType: 3
          },
          equipmentBox: false,
          equipmentBoxData: [],
          equipmentShuttle: {
            jourDeviceId: [],//穿梭框id
          },
          shuttleGiveData: [],//穿梭框赋值数组
          addPersonnel: false,//人员弹出框
          addPersonnelState: false,//保存状态
          options: [],
          bindingPersonnel: '',//绑定人员拉下
          userCoding: null,//用户公用变量
          banEquipmentShuttle: {
            jourDeviceId: [],//穿梭框id
          },
          banEquipmentBox: false,//禁止设备穿梭框
          banEquipmentBoxData: [],//房间下的设备
          banEquipmentGiveArr: [],
          banTitle: '',//禁止设备标题
          banState: false,
        //区域管理
          areaData: [], //区域管理列表信息
          addArea: false,//新增区域
          areaName: '',//区域名称
          monitoringBox: false,//监测设备穿梭框
          monitoringBoxData: [],//监测设备数据
          GiveData: [],//穿梭框赋值数组
          addAreaState: false,//保存按钮状态
          addAreaTitle: '',
          shuttleBox: {
            jourDeviceId: [],
          },
          rowArea: '',
          title: '',//穿梭框标题
          ggjrdDeviceType: 0,
          monitoringRelated: {
            title: '选择监测设备',
            jrdDeviceType: 1
          },
          publicRelated: {
            title: '选择公共设备',
            jrdDeviceType: 2
          },
          locationRelated: {
            title: '选择位置设备',
            jrdDeviceType: 3
          },
        //上传图片
          dialogImgPath:false,
          needSavePhoto:false,
          photoTableId:'',
          photoTableType:'',
          photoRandom:'',
          textkey:1,
      }
    },
    computed: {
     ...mapState(['userName','userNameList','cityList','companyVars','currentUser','authe','pageAuth'])
    },
    created(){
      if (this.pageAuth['/publicArea']) {
        this.getHomeData()
        this.getSetApprData()
      } else {
        this.$router.replace('/notfind')
      }
    },
    watch:{
      activeName(val){
        if(val=='public'){

        }else if(val=='asset'){
          this.getAllElectric(1)
        }else if(val=='approval'){
          this.getApprovalPapers(1)
        }else if(val=='task'){
          this.getOddjobs(1)
        }else if(val=='budget'){
          this.getRecords(1)
        }else if(val=='fourth'){
          this.getAllArea()
        }else if(val=='third'){
        }else if(val=='equipment'){
          this.getDataRoomEquipment()
        }else if(val=='third'){
          this.scenarioModeChange()
        }
        this.assetForm.homeRow = ''
        this.approvalRow = ''
      },
      // $route(to,from){
      // if(to.params.hsAddCommunity){
      //   this.getHomeData(1)
      // }
    // }
    },
    methods:{
    //通用
      tableRowShowInput({row,rowIndex}){//获取表格当前页的数据，并添加索引值
        row.index=rowIndex
      },
      numberChinese(str) {//金额转中文
        let num = parseFloat(str);
        let strOutput = '',
            strUnit = '仟佰拾亿仟佰拾万仟佰拾元角分';
        num += '00';
        let intPos = num.indexOf('.');
        if (intPos >= 0){
            num = num.substring(0, intPos) + num.substr(intPos + 1, 2);
        }
        strUnit = strUnit.substr(strUnit.length - num.length);
        for (let i=0; i < num.length; i++){
            strOutput += '零壹贰叁肆伍陆柒捌玖'.substr(num.substr(i,1),1) + strUnit.substr(i,1);
        }
        return strOutput.replace(/零角零分$/, '整').replace(/零[仟佰拾]/g, '零').replace(/零{2,}/g, '零').replace(/零([亿|万])/g, '$1').replace(/零+元/, '元').replace(/亿零{0,3}万/, '亿').replace(/^元/, '零元')
      },
      taskCostTime(t1,t2){//计算耗时时间
        let costTime = '';
        t2 = !t2 ? moment().format('YYYY-MM-DD HH:mm:ss') : t2;
        console.log(t2)
        t1 = new Date(t1);
        t2 = new Date(t2);
        t1 = t1.getTime();
        t2 = t2.getTime();
        console.log(t2)
        let subTime = parseInt((t2-t1)/1000/60);
        if(subTime<60){
          costTime = subTime+'分';
          return costTime;
        }

        if((subTime/60)>=1 && (subTime/60/24)<1){
          if(subTime%60>0){
            costTime = (subTime/60).toString().split('.')[0]+'小时'+(subTime%60)+'分';
          }else{
            costTime = (subTime/60)+'小时';
          }
          return costTime;
        }
        if((subTime/60/24)>=1){
          if(parseInt(subTime/60)%24>=1){
            costTime = (subTime/(60*24)).toString().split('.')[0]+'天'+(parseInt(subTime/60)%24)+'小时';
          }else{
            costTime = parseInt(subTime/(60*24))+'天';
          }
          return costTime;
        }
      },
      taskTime(type){//任务时间格式
        let time=''
        if(type=='尽快'|| type=='电话联系'|| type=='今天'){
          time=moment().format('YYYY-MM-DD HH:mm:ss')
        }else if(type=='明天'){
          time=moment().add(1,'days').format('YYYY-MM-DD HH:mm:ss')
        }else if(type=='后天'){
          time=moment().add(2,'days').format('YYYY-MM-DD HH:mm:ss')
        }else if(type=='本周末'){
          time=moment().day(0).format('YYYY-MM-DD HH:mm:ss'); // 这个星期日 (0)
        }
        return time
      },
      getSetApprData(){//获取审批流程,添加审批需要
        this.$axios({
          url: '/common1/examineApprovalProcess',
          method: 'get',
          params:{
            jeapState:1
          }
        }).then(res => {
          this.appList=res.data.result.records
        }).catch(err => {
          this.isError(err,this)
        })
      },
      dialogImgPathClose(){//上传图片弹窗关闭
        this.photoList = []
        this.dialogImgPath=false
      },
    //首页
      getHomeData(t){
        if(t==1){
          this.homeParms.cursor=1
        }
        this.$axios({
          method:'get',
          url:'/common1/house4store',
          params:this.homeParms
        }).then(res=>{
          this.homeData=res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      },
      meterCurChange(val) {//分页
        this.homeParms.cursor = val;
        this.getHomeData();
      },
      meterSizeChange(val) {//分页
        this.homeParms.limit = val;
        this.getHomeData(1);
      },
      addWarehouse(){//添加办公室按钮
        this.wareTitle='添加办公室'
        this.dialogWarehouse=true
      },
      homeDataClick(row){//首页表格单击
        this.homeRow=row
      },
      updateWarehouse(){//修改办公室按钮
        if(!this.homeRow.hsId){
          this.$message.warning('请选择一项')
          return
        }
        for(let i in this.addData){
          for(let k in this.homeRow){
            if(i==k){
              this.addData[i]=this.homeRow[k]
            }
          }
        }
        this.wareTitle='修改办公室'
        this.dialogWarehouse=true
      },
      saveRoom(){//添加修改办公室
        if(!this.addData.hsAddCommunity){
          this.$message.warning('办公室名称不能为空')
          return
        }
        if(this.wareTitle=='添加办公室'){//添加办公室
          this.loading=true
          this.$axios({
            method:'post',
            url:'/common1/house4store/createPublicHs',
            data:this.addData
          }).then(res=>{
            this.dialogWarehouse=false
            this.loading=false
            this.$message.success('添加成功')
            this.homeRow={}
            this.getHomeData()
          }).catch(err => {
            this.loading=false
            this.isError(err,this)
          })
        }else if(this.wareTitle=='修改办公室'){//修改办公室
          this.loading=true
          this.$axios({
            method:'put',
            url:`/common1/house4store/updatePublicHs/${this.homeRow.hsId}`,
            data:this.addData
          }).then(res=>{
            this.dialogWarehouse=false
            this.loading=false
            this.$message.success('修改成功')
            this.homeRow={}
            this.getHomeData()
          }).catch(err => {
            this.loading=false
            this.isError(err,this)
          })
        }
      },
      dialogWarehouseClose(){//添加办公室弹窗关闭
        this.addData={//办公室添加修改数据
          hsAddCommunity:'',
          hsHouseNote:'',
          hsState:'正常',
          hsAddCity: '库房',
        }

      },
    //公共选择房间
      selectAddressBelong(str){//选择房间
        this.costRelationTitle = str
        this.costRelationVisible = true
      },
      selectRoomDblclick(row) {//双击一行房源
        if (row) {
          if (this.costRelationTitle == '选择地址' || this.costRelationTitle == '选择库房') {
            this.roomAddress = row.address
            this.roomHsId = row.hsId
          //   this.addAssetsForm.assets.assetsBelong = row.address;
          //   this.addAssetsForm.moveHsId = row.hsId;
          } else if (this.costRelationTitle == '选择供应商') {
            this.addAssetsForm.assets.saSupplierName = row.keyAdministrator
            this.addAssetsForm.assets.saSupplier = row.houseCoding
          }
        }
        this.selectedHouse = row
        this.costRelationVisible = false;
      },
    //双击弹框
      homeDataDblclick(row){//首页表格双击
        this.homeRow=row
        console.log(row)
        this.activeName='public'
        this.dialogInfo=true
      },
    //资产
      homeTableClick(row){//资产表格点击
        this.assetForm.homeRow=row
        console.log(this.assetForm.homeRow)
      },
      homeTableSelect(sel){//资产表格勾选
        this.assetForm.homeSel=[]
        this.printCodeForm.selList=[]
        if(sel.length>0){
          sel.forEach(item=>{
            this.assetForm.homeSel.push(item.saId)
          })
          this.printCodeForm.selList=sel
        }
      },
      cellStyle(row,column){//修改表格列的颜色
        if(row.column.label == '状态'){
          if(row.row.saUse == '使用中'){
            return 'color:green'
          }else if(row.row.saUse == '未使用'){
            return 'color:blue'
          }else if(row.row.saUse == '已报废'){
            return 'color:gray'
          }
        }
      },
      getAllElectric(t) {//分页查询资产
        this.assetForm.params.limit = this.assetForm.limit;
        this.assetForm.params.cursor = this.assetForm.cursor;
        if(t==1){
          this.assetForm.params.cursor = 1
        }
        this.assetForm.params.saHouseStoreId=this.homeRow.hsId
        this.$axios({
          method: 'get',
          url: '/common1/companyAssets',
          params: this.assetForm.params
        }).then(res => {
          res.data.result.records.forEach(item => {
            item.addCommunity = item.addCommunity ? item.addCommunity : ''
            item.roomType = item.roomType ? item.roomType : ''
            item.addDoorplateno = item.addDoorplateno ? item.addDoorplateno : ''
            item.address = `${item.addCommunity}\u3000${item.roomType}\u3000${item.addDoorplateno}`
            item.address = item.addCity == '公区' || item.addCity == '办公室' || item.addCity == '库房' ? item.addCommunity : item.address
          });
          this.assetForm.electricList = res.data.result.records;
          this.assetForm.total = res.data.result.total;
          console.log(this.assetForm.electricList)
        }).catch(err => {
          this.isError(err,this)
        });
      },
      electricCurChange2(val) {//资产分页
        this.assetForm.cursor = val;
        this.getAllElectric();
      },
      addAssetsClick(){//添加资产按钮
        this.addAssetsForm.dialogAddElectricTitle='添加资产'
        this.addAssetsForm.onlyFixAssets='1'
        this.addAssetsForm.dialogAddElectric=true
      },
      fixAssetsClick(){//修改资产按钮
        if(!this.assetForm.homeRow){
          this.$message.warning('请选择一个资产')
          return
        }
        for(let i in this.assetForm.homeRow){
          for(let k in this.addAssetsForm.assets){
            if(i==k){
              this.addAssetsForm.assets[k]=this.assetForm.homeRow[i]
            }
          }
          this.addAssetsForm.assets.assetsBelong=this.homeRow.address
          this.addAssetsForm.assets.saSupplierName=this.assetForm.homeRow.saSupplierName
        }
        this.classifyChange()
        this.addAssetsForm.dialogAddElectricTitle='修改资产'
        this.addAssetsForm.dialogAddElectric=true
        this.addAssetsForm.onlyFixAssets='2'
      },
      putElectric(){//修改保存资产按钮
        if (this.submitForm(['electric'], this)) {
          this.loading=true
          this.$axios({
            method:'put',
            url:`/common1/companyAssets/${this.assetForm.homeRow.saId}`,
            data:this.addAssetsForm.assets
          }).then(res=>{
            this.addAssetsForm.dialogAddElectric = false
            this.loading=false
            this.$message.success('修改成功')
            this.getAllElectric()
            console.log(res)
          }).catch(err => {
            this.loading = false;
            this.isError(err,this)
          });
        }
      },
      addAssets2() {//添加资产添加按钮
        if (this.submitForm(['electric'], this)) {
          if (this.addAssetsForm.numbers > 0) {
            for (let i = 0; i < Number(this.addAssetsForm.numbers); i++) {
              let obj = {
                saType: this.addAssetsForm.assets.saType,//公司
                saClassify: this.addAssetsForm.assets.saClassify,//办公设备
                saUse: this.addAssetsForm.assets.saUse,//未使用
                saStatus: this.addAssetsForm.assets.saStatus,//正常
                saName: this.addAssetsForm.assets.saName,//打印机
                saBrand: this.addAssetsForm.assets.saBrand,//A牌
                saModel: this.addAssetsForm.assets.saModel,//型号
                saPrice: this.addAssetsForm.assets.saPrice,//价格
                saRemarks: this.addAssetsForm.assets.saRemarks,//00备注00
                assetsBelong:this.homeRow.hsAddCommunity,//资产所属
                saSupplierName:this.addAssetsForm.assets.saSupplierName,//供应商
                saSupplier:this.addAssetsForm.assets.saSupplier,//供应商Id
              }
              this.addAssetsForm.assetsPARMS.push(obj);
            }
            console.log(this.addAssetsForm.assetsPARMS)
          } else {
            this.$message.warning('请填写数量');
          }
        } else {
          this.$message.warning('有必填项未填');
        }
      },
      postElectric() {//添加资产保存按钮
        if (this.addAssetsForm.assetsPARMS.length == 0) {
          this.$message.warning('请添加资产');
          return;
        }
        let obj = {
          saHouseStoreId: this.homeRow.hsId,
          assetsPARMS: this.addAssetsForm.assetsPARMS
        };
        console.log(this.addAssetsForm.assetsPARMS)
        this.loading = true;
        this.$axios({
          method: 'post',
          url: '/common1/companyAssets/saveOrUpdateBatch',
          data: obj
        }).then(res => {
          this.loading = false;
          this.addAssetsForm.dialogAddElectric = false;
          this.$message.success('添加成功');
          this.getAllElectric()
        }).catch(err => {
          this.loading = false;
          this.isError(err,this)
        });
      },
      dialogAddElectricClose() {//添加资产弹窗关闭
        for (let i in this.addAssetsForm.assets) {
          this.addAssetsForm.assets[i] = '';
        }
        this.addAssetsForm.numbers = '';
        this.addAssetsForm.assetsPARMS = [];
        this.resetForm(['electric'], this);

      },
      uploadPhoto(){//上传图片
        this.needSavePhoto=true//需要保存按钮
        this.photoTableId=this.assetForm.homeRow.saId
        this.photoTableType='资产'
        this.photoRandom=parseInt((Math.random() * 9 + 1) * 100000000000)
        this.dialogImgPath=true
        this.$nextTick(()=>{
          this.$refs.upload.photoReflash()
        })
      },
      classifyChange(){//根据资产类型获取设备名称
        this.addAssetsForm.assetsType=[]
        console.log(this.$store.state.companyVars.assetsTypeList)
        for(let i in this.$store.state.companyVars.assetsTypeList){
          if(this.addAssetsForm.assets.saClassify==this.$store.state.companyVars.assetsTypeList[i].type){
            this.addAssetsForm.assetsType=this.$store.state.companyVars.assetsTypeList[i].name
          }
        }
      },
      moveOutClick() {//迁出按钮
        if (!this.assetForm.homeRow) {
          this.$message.warning('请选择需要迁出的资产');
          return;
        }
        if(this.assetForm.homeRow.hsAddCommunity){
          this.moveOutForm.address=this.assetForm.homeRow.hsAddCommunity
        }else{
          this.moveOutForm.address=this.assetForm.homeRow.address
        }
        this.moveOutForm.dialogMoveOut = true;
      },
      handIdChange(val) {//经手人改变
        if (val) {
          this.moveOutForm.saAgent = val[2];
        }
      },
      saveMoveOutAss() {//迁出保存按钮
        if (!this.moveOutForm.saAgent) {
          this.$message.warning('请选择经手人');
          return;
        }
        let assetsPARMS = [];
        let obj = {
          saId: this.assetForm.homeRow.saId,
          saMoveFrom: this.homeRow.hsAddCommunity,
          saNumber: this.assetForm.homeRow.saNumber
        };
        assetsPARMS.push(obj);
        let data2 = {
          saHouseStoreId: this.roomHsId,
          saAgent: this.moveOutForm.saAgent,
          moveReason: this.moveOutForm.moveReason,
          saMoveTo:this.roomAddress,
          assetsPARMS: assetsPARMS
        };
        this.loading = true;
        this.$axios({
          method: 'put',
          url: '/common1/companyAssets/move',
          data: data2
        }).then(res => {
          this.loading = false;
          this.moveOutForm.dialogMoveOut = false;
          this.$message.success('迁出成功');
          this.assetForm.homeRow={}
          this.getAllElectric()
        }).catch(err => {
          this.loading = false;
          this.isError(err,this)
        });
      },
      dialogMoveOutClose() {//迁出弹窗关闭
        this.moveOutForm.moveReason = '';
        this.moveOutForm.handId = '';
        this.moveOutForm.moveFrom = '';
        this.roomAddress = ''
        this.roomHsId = ''
      },
      deleteRow(index, rows) {//添加资产删除按钮
        rows.splice(index, 1)
      },
      clearAssets(){//添加资产清除按钮
        for(let i in this.addAssetsForm.assets){
          this.addAssetsForm.assets[i]=''
        }
        this.$refs['electric'].resetFields()
        // this.resetForm(['electric'],this)
        this.addAssetsForm.numbers=null
      },
      homeTableDblclick(row){//资产表格双击
        this.assetForm.homeRow=row
        this.getAssFoll()
        this.assetForm.dialogElectric=true
      },
      getAssFoll(){//获取资产跟进
        this.assFollowData={}
        this.$axios({
          method:'get',
          url:'/common1/eventFollowupRecords',
          params:{
            limit:this.assFolLimit,
            cursor:this.assFolCur,
            jhfTableType:'资产',
            jhfTableId:this.assetForm.homeRow.saId
          }
        }).then(res=>{
          this.assFollowData=res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      },
      assCurChange(cur){//资产跟进分页
        this.assFolCur=cur
        this.getAssFoll()
      },
      nextPrice(){//下一条
        let i=this.assetForm.homeRow.index+1
        if(i==this.assetForm.electricList.length){
          this.$message.warning('当前已是本页最后一条数据')
          return
        }
        this.assetForm.homeRow=this.assetForm.electricList[i]
        this.$refs.homeTable.setCurrentRow(this.assetForm.electricList[i])
        this.homeTableDblclick(this.assetForm.homeRow)
      },
      prvPrice(){//上一条
        let i=this.assetForm.homeRow.index-1
        if(i==-1){
          this.$message.warning('当前已是本页第一条数据')
          return
        }
        this.assetForm.homeRow=this.assetForm.electricList[i]
        this.$refs.homeTable.setCurrentRow(this.assetForm.electricList[i])
        this.homeTableDblclick(this.assetForm.homeRow)
      },
      saFollowUpClick(row) {//跟进表格双击
        this.assetForm.dialogFollowup = true;
        this.assetForm.followupRow = row;
        console.log(row)
      },
      printCodeClick(){//打印资产标识卡
        let co=sessionStorage.getItem('co')
        this.printCodeForm.urlList=[]
        if(this.assetForm.homeSel.length==0){
          this.$message.warning('请勾选需要打印的资产')
          return
        }
        this.printCodeForm.selList.forEach(item=>{
          let obj={
            url: `http://fzz1.cn/b?c=${co}&a=${item.saId}`,
            number:item.saNumber,
            address:item.address,
            name:item.saName,
            status:item.saStatus,
            model:item.saModel,
            brand:item.saBrand
          }
          this.printCodeForm.urlList.push(obj)
        })
        this.printCodeForm.dialogprintCode=true
      },
    //审批
      getApprovalPapers(t){//分页查询审批
        this.approvalParams.eaStoreId=this.homeRow.hsId
        if(t==1){
          this.approvalParams.cursor=1
        }
        this.approvalData={}
        this.$axios({
          method:'get',
          url:'/common1/approvalPapers',
          params:this.approvalParams
        }).then(res=>{
          // if(res.data.result.records.length>0){
          //   res.data.result.records.forEach(item=>{
          //     item.useTime=this.taskCostTime(item.eaReleaseTime,item.eaUseTime)
          //   })
          // }
          this.approvalData=res.data.result
        }).catch(err => {
          this.isError(err,this)
        })
      },
      approCurChange(cur){//审批分页
        this.approvalParams.cursor=cur
        this.getApprovalPapers()
      },
      addApproval(){//添加审批按钮
        this.photoRandom=parseInt((Math.random() * 9 + 1) * 100000000000)
        this.addAppro.eaApprovalNumber= this.randomNumber()
        this.dialogAppro=true
      },
      eaProcessChange(val){//审批流程选择框
        if(!val){
          return
        }
        this.eaEventHandler=[]
        if(val=='自由流程'){
          this.addAppro.eaEventType='自由流程'
        }else{
         let obj = this.appList.find((item)=>{//这里的appList就是上面遍历的数据源
            return item.jeapId == val;//筛选出匹配数据
          });
          console.log(obj)
          this.addAppro.eaEventContent=obj.jeapContent
          let hand=JSON.parse(obj.jeapProcess)[0]
          if(hand.storeId){
            this.eaEventHandler[0]=hand.storeId
          }
          if(hand.departmentId){
            this.eaEventHandler[1]=hand.departmentId
          }
          if(hand.handlerId){
            this.eaEventHandler[2]=hand.handlerId
          }
          console.log(this.eaEventHandler)
          this.addAppro.eaEventType=obj.jeapType

          if(this.eaEventHandler.length==2){
            let array = [];
            for (let j in this.userNameList) {
              if (this.eaEventHandler[0] ==this.userNameList[j].value) {
                array.push({
                  value:this.userNameList[j].value,
                  label:this.userNameList[j].label,
                  children:[]
                });
                for(let k in this.userNameList[j].children){
                  if(this.eaEventHandler[1] ==this.userNameList[j].children[k].value){
                    array[0].children.push(this.userNameList[j].children[k])
                  }
                }
              }
            }
            this.demoOpt = array;
            this.isArea = true;
          }else if(this.eaEventHandler.length==1){
            let array = [];
            for (let j in this.userNameList) {
              if (
                this.eaEventHandler[1] ==
                this.userNameList[j].value
              ) {
                array.push(this.userNameList[j]);
              }
            }
            this.demoOpt = array;
            this.isArea = true;
          }else{
            this.isArea = false;
          }
          console.log(this.demoOpt,this.userNameList)
        }
      },
      uploadOfAppro(){//上传审批图片
        this.needSavePhoto=false//需要保存按钮
        this.photoTableId=''
        this.photoTableType='审批'
        this.dialogImgPath=true
      },
      //获取银行账号列表
      getBankAccData(t) {
        if(t==1){
          this.bankCur=1
        }
        this.$axios({
          url: `/common1/accountReceipt`,
          method: 'get',
          params: {
            csAccountName: this.scAccName,
            csBank: this.scBank,
            cursor: this.bankCur,
            limit: this.bankLim
          }
        }).then(res => {
          console.log(res);
          this.bankAccData = res.data.result;
        }).catch(err => {
          this.isError(err,this)
        });
      },
      bankCurChange(cur){
        this.bankCur=cur
        this.getBankAccData()
      },
      //打开选择账号对话框
      selAcc() {
        this.getBankAccData(1);
        this.selBankAccDia = true;
      },
      //双击选择账号
      dbSelAcc(row) {
        console.log(row);
        this.addAppro.eaBankUsername = row.csAccountName;
        this.addAppro.eaBankName = row.csBank;
        this.addAppro.eaBankAccountNumber = row.csAccountUmber;
        this.addAppro.eaSubordinateBranch = row.csSubordinateBranch;
        this.addAppro.eaBankAccountDescription = row.csAccountDescription;
        this.selBankAccDia = false;
      },
      saveApproval(){//添加审批保存
        if(this.eaEventHandler.length==0){
          this.$message.warning('审批人不能为空')
          return
        }
        if(!this.addAppro.eaProcessId){
          this.$message.warning('审批类型不能为空')
          return
        }
        if(!this.addAppro.eaEventContent){
          this.$message.warning('审批内容不能为空')
          return
        }
        this.addAppro.addCommunity=this.homeRow.hsAddCommunity
        this.addAppro.eaProcessId=this.addAppro.eaProcessId=='自由流程'?'':this.addAppro.eaProcessId
        this.addAppro.eaStoreId=this.homeRow.hsId
        this.addAppro.priorityFlag=this.firstCheck?1:2
        this.addAppro.sendMessageFlag=this.msgChecked?1:2
        this.addAppro.randomNum=this.photoRandom
        this.addAppro.tableType='审批'
        this.addAppro.eaEventState='处理中'
        this.addAppro.eaHomeType='其他审批'
        this.addAppro.eaWhetherGenerateRecord='否'
        this.addAppro.eaEventHandler=this.eaEventHandler[2]
        this.loading=true
        this.$axios({
          method:'post',
          url:'/common1/approvalPapers',
          data:this.addAppro
        }).then(res => {
          this.loading = false;
          this.dialogAppro= false;
          this.$message.success('添加成功');
          this.getApprovalPapers()
        }).catch(err => {
          this.loading = false;
          this.isError(err,this)
        });
      },
      dialogApproClose(){//审批弹窗关闭
        for(let i in this.addAppro){
          this.addAppro[i]=''
        }
        this.msgChecked=false
        this.firstCheck=false
        this.eaEventHandler=[]

      },
      approvalClick(row){//表格单击
        this.approvalRow=row
        console.log(row)
      },
      approvalDblClick(row){//表格双击
        this.eaTreatmentOpinion=[]
        this.approvalRow=row
        this.dialogAppDbl=true
        this.$axios({//单条查审批
          url: `/common1/approvalPapers/${row.eaId}`,
          method: 'get'
        }).then(res => {
          if (typeof res.data.result.eaTreatmentOpinion == 'string') {
            this.eaTreatmentOpinion = JSON.parse(`[${res.data.result.eaTreatmentOpinion}]`);
          }
        }).catch(err => {
          this.isError(err,this)
        })
        if(row.eaWhetherGenerateRecord=='是'){//通过流水号查付款详情
          this.$axios({
            method:'get',
            url:'/common1/financialRecords/list',
            params:{
              jfFinancialCoding:row.eaFinancialCoding
            }
          }).then(res=>{
            let obj=res.data.result.records[0]
            obj.faPaymentType=obj.faPaymentType?obj.faPaymentType:''
            obj.faUserName=obj.faUserName?obj.faUserName:''
            obj.faBelonging=obj.faBelonging?obj.faBelonging:''
            obj.faAccount=obj.faAccount?obj.faAccount:''
            this.approvalRow.payInfo=obj.faPaymentType+'-'+obj.faUserName+'-'+obj.faBelonging+'-'+obj.faAccount
            this.approvalRow.jfCheckInTime=obj.jfCheckInTime?obj.jfCheckInTime:''
          }).catch(err => {
          this.isError(err,this)
        })
        }
      },
      eaTreatmentDblclick(row){//审批进展双击
        this.dialogAppFol=true
        this.appFollowRow=row
      },
      approPrv(){//上一条
        let i=this.approvalRow.index-1
        if(i==-1){
          this.$message.warning('当前已是本页第一条数据')
          return
        }
        this.approvalRow=this.approvalData.records[i]
        this.$refs.appro.setCurrentRow(this.approvalData.records[i])
        this.approvalDblClick(this.approvalRow)
      },
      approNext(){//下一条
        let i=this.approvalRow.index+1
        if(i==this.approvalData.records.length){
          this.$message.warning('当前已是本页最后一条数据')
          return
        }
        this.approvalRow=this.approvalData.records[i]
        this.$refs.appro.setCurrentRow(this.approvalData.records[i])
        this.approvalDblClick(this.approvalRow)
      },
      seePhotoClick(){//查看附件按钮
        if(!this.approvalRow.eaId){
          this.$message.warning('请选择一项')
          return
        }
        this.getPhotoByTableId()
        this.dialogSeeImgPath=true
      },
      async getPhotoByTableId(){//通过外表id查已有图片
        await this.$axios({
          method:'get',
          url:'/common1/photos',
          params:{
            photoTableId:this.approvalRow.eaId,
            photoTableType:'审批'
          }
        }).then(async res=>{
          if(res.data.result.length==0){
            return
          }
          let imgdata = res.data.result;
          for (let i = 0; i < imgdata.length; i++) {//将图片拼接为element识别格式
            if(imgdata[i].photoMimeType=='图片'){
              this.imgNum=this.imgNum+1
            }else if(imgdata[i].photoMimeType=='文件'){
              this.fileNum=this.fileNum+1
            }
            let obj = {
              photoStatus:imgdata[i].photoStatus,
              photoMimeType:imgdata[i].photoMimeType,
              name: imgdata[i].photoName,
              url:await this.getDownloadUrl(imgdata[i].photoUrl),
              response: {
                result: imgdata[i].photoId
              }
            };
            this.photoList.push(obj);
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
      getDownloadUrl(url){//下载凭证获取图片路径
        return this.$axios({//下载凭证获取图片路径
          method:'get',
          url:'/common1/photos/getDownloadUrl',
          params:{
            baseUrls:url
          }
        }).then(res=>{
          return res.data.result[0].original
        }).catch(err => {
          this.isError(err,this)
        })
      },
    //任务
      getOddjobs(){//分页查询任务
        this.$axios({
          method:'get',
          url:'/common1/oddjobs',
          params:{
            limit:this.taskLim,
            cursor:this.taskCur,
            hsId:this.homeRow.hsId,
            oddjobsType:2
          }
        }).then(res=>{
          this.taskData=res.data.result
          if(this.taskData.records.length>0){
            this.taskData.records.forEach(item=>{
              if(!item.repFinishTime){
                item.repFinishTime='未完成'
              }
              if(!item.timeConsuming){
                item.timeConsuming='未领取'
              }
            })
          }
        })
      },
      taskCurChange(cur){//任务分页
        this.taskCur=cur
        this.getOddjobs()
      },
      addTaskClick(){//添加任务按钮
        this.dialogTask=true
        this.addTask.repContactsPhone=this.homeRow.houseEntrust4rent
        this.addTask.repContacts=this.homeRow.keyNumber
        this.photoRandom=parseInt((Math.random() * 9 + 1) * 100000000000)
      },
      uploadOfTask(){//添加任务上传图片
        this.needSavePhoto=false//需要保存按钮
        this.photoTableId=''
        this.photoTableType='任务'
        this.dialogImgPath=true
      },
      saveTask(){//保存任务
        if(!this.repRepairPeopleId){
          this.$message.warning('请选择负责人')
          return
        }
        if(!this.addTask.repTypeRp){
          this.$message.warning('请选择任务类型')
          return
        }
        if(!this.addTask.repEventRp){
          this.$message.warning('任务描述不能为空')
          return
        }
        if(!this.addTask.repGrade){
          this.$message.warning('任务等级不能为空')
          return
        }
        this.addTask.repTaskTime=this.taskTime(this.addTask.repHopeTime)
        // if(this.addTask.repHopeTime=='本周末'){
        //   let time=moment().format('E')
        //   this.addTask.repHopeTime=moment().add(6-time, 'days').format('YYYY-MM-DD')+'或'+moment().add(7-time, 'days').format('YYYY-MM-DD')
        // }
        this.addTask.repResponsibility='负责人'
        this.addTask.repRepairPeopleId=this.repRepairPeopleId[2]
        this.addTask.address=this.homeRow.hsAddCommunity
        this.addTask.hsId=this.homeRow.hsId
        this.addTask.oddjobsType=2
        this.addTask.sendWxMessage='2'
        this.addTask.randomNum=this.photoRandom
        this.addTask.tableType='任务'
        this.loading=true
        this.$axios({
          method:'post',
          url:'/common1/oddjobs',
          data:this.addTask
        }).then(res => {
          this.loading = false;
          this.dialogTask= false;
          this.$message.success('添加成功');
          this.getOddjobs()
        }).catch(err => {
          this.loading = false;
          this.isError(err,this)
        });
      },
      dialogTaskClose(){//添加任务弹窗关闭
        this.repRepairPeopleId=''
        this.addTask={
          repEventRp:'',
          repContactsPhone:'',
          repContacts:'',
          repHopeTime:'尽快',
          sendMessage:'1',
          repTaskTime:'',
          repGrade:'1',
        }
        this.photoList = []
        this.taskCur=1
      },
      taskDataDblclick(row){//任务表格双击
        this.taskRow=row
        console.log(row)
        this.$axios({
          method:'get',
          url:`/common1/oddjobsFollowup/${this.taskRow.repId}`
        }).then(res=>{
          this.taskFollowData=res.data.result.records
        }).catch(err => {
          this.isError(err,this)
        })
        this.dialogTaskDbl=true
      },
      taskUpload(){//查看任务上传图片
        this.photoRandom=parseInt((Math.random() * 9 + 1) * 100000000000)
        this.needSavePhoto=true//需要保存按钮
        this.photoTableId=this.taskRow.repId
        this.photoTableType='任务'
        this.dialogImgPath=true
        this.$nextTick(()=>{
          this.$refs.upload.photoReflash()
        })
      },
      taskFollDblclick(row){//任务进展双击
        this.dialogTaskFol=true
        this.taskFollowRow=row
      },
      taskPrv(){//上一条
        let i=this.taskRow.index-1
        if(i==-1){
          this.$message.warning('当前已是本页第一条数据')
          return
        }
        this.taskRow=this.taskData.records[i]
        this.$refs.task.setCurrentRow(this.taskData.records[i])
        this.taskDataDblclick(this.taskRow)
      },
      taskNext(){//下一条
        let i=this.taskRow.index+1
        if(i==this.taskData.records.length){
          this.$message.warning('当前已是本页最后一条数据')
          return
        }
        this.taskRow=this.taskData.records[i]
        this.$refs.task.setCurrentRow(this.taskData.records[i])
        this.taskDataDblclick(this.taskRow)
      },
    //收支
      getRecords(t) {//分页查询收支记录
        if(t==1){
          this.commonQuery.cursor=1
        }
        this.commonQuery.hsId=this.homeRow.hsId
        if(this.Time !== null) {
          this.commonQuery.startTime = this.Time[0]
          this.commonQuery.endTime = this.Time[1]
        }else {
          this.commonQuery.startTime = null
          this.commonQuery.endTime = null
        }
        this.$axios({
          url: '/common3/financialRecords',
          method: 'get',
          params: this.commonQuery
        }).then(res => {
          this.budgetData = res.data.result;
        }).catch(err => {
          this.isError(err,this)
        });
      },
      budgetCurChange(cur){//收支分页
        this.commonQuery.cursor=cur
        this.getRecords()
      },
      budgetDblclick(row){//收支表格双击
        this.budgetRow=row
        this.dialogBudget=true
      },
    //智能设备
      getDataRoomEquipment(){//办公室绑定设备
        this.$axios({
          method: 'get',
          url: `/common2/house4stores/${this.homeRow.hsId}/devices`,
          // url: `/campus/house4stores/${1123}/devices`,
          params: {
            scenarios: false,
            scenariosPanel: false
          }
        }).then(res => {
          if(res.data.status==200 && res.data.result.records.length >= 1){
            this.dataRoomEquipment = res.data.result.records
            this.getRoomEquipmentState()
          }else{
            this.dataRoomEquipment = []
          }
        }).catch(err => {
          console.log(err)
        })
      },
      getRoomEquipmentState(){//获取办公室绑定设备状态
        let ids = []
        for (let i = 0 ; i < this.dataRoomEquipment.length ; i++) {
          ids.push(this.dataRoomEquipment[i].jourDeviceId)
        }
        this.$axios({
          method: 'get',
          // url: `/common2/devices/getBatchState`,
          url: `/common2/devices/getDevicesState`,
          params: {
            ids: ids.join(',')
          }
        }).then(res => {
          if(res.data.status==200){
            for (let i of this.dataRoomEquipment) {
              for (let j of res.data.result) {
                if (i.jourDeviceId == j.id) {
                  i.online = j.online ? '在线' : '离线'
                  i.stateInformation = j.stateInformation
                }
              }
            }
            this.equipmentStateKey ++
          }
        }).catch(err => {
          console.log(err)
        })
      },
      equipmentOperation(){//设备控制按钮+表格双击
        if(Object.keys(this.equipmentRow).length){
          this.$message.warning('请先选中一个设备')
        }else{
        this.getOneState()
        this.dialogCaoZuoSheBei = true
        }
      },
      currentChangeEquipment(row){//设备列表选中行
        this.equipmentRow = row
        this.changestatus.devSn=row.devSn
        this.changeStatus.jourDeviceId=row.jourDeviceId
        this.panelForm.devSecondType=row.devSecondType
        this.panelForm.devFirstType=row.devFirstType
      },
      getOneState(){//获取单条设备状态
        if(this.panelForm.devSecondType==1){
          this.$axios({
            method:'get',
            url:'/common2/eventsCodeStorages',
            params:{
              sn:this.changestatus.devSn
            }
          }).then(res=>{
            this.changestatus.csRoad=res.data.result.csRoad + '路'
            this.changestatus.csRoad2=res.data.result.csRoad
            this.changestatus.csRoad3=res.data.result.csRoad
          }).catch(err=>{
            this.changestatus.csRoad=''
            this.$message.error(err.response.data.msg)
          })
        }
        let idj=this.equipmentRow.jourDeviceId
        this.$axios({
          method: 'get',
          // url: '/common2/devices/getBatchState',
          url: `/common2/devices/getDevicesState`,
          params: {
            ids: idj
          }
        }).then(res=>{
          if(res.data.status==200){
          res.data.result[0].brightness=parseInt(res.data.result[0].brightness)
          this.changestatus.nowStates=res.data.result[0]
          this.changestatus.devAirTemperature=this.changestatus.nowStates.temperature
          this.changestatus.devAirPattern=this.changestatus.nowStates.mode
          this.changestatus.devState=this.changestatus.nowStates.switchingState
          this.changestatus.devAirWindSpeed=this.changestatus.nowStates.speed
          this.changestatus.devAirFactors=this.changestatus.nowStates.powerFactor
          this.changestatus.devAirCurrent=this.changestatus.nowStates.electricCurrent
          this.changestatus.devAirVoltage=this.changestatus.nowStates.voltage
          this.changestatus.devAirPower=this.changestatus.nowStates.power
          this.changestatus.devAirElectricity=this.changestatus.nowStates.electricityConsumption
          this.changestatus.devAirSweepingWind=this.changestatus.nowStates.airSpeedWind
          this.changestatus.devAirTemperature2=this.changestatus.nowStates.deviceTemperature
          this.changestatus.devAirProtectTemperature=this.changestatus.nowStates.protectionTemperature
        }})
        .catch(err=>{
          this.$message.error(err.response.data.msg)
        })
      },
      changeStatus(){//改变设备状态
        this.loading = true
        this.$axios({
          method:'post',
          url:'/common2/devices/operate',
          data:{
            devAirPattern:this.changestatus.devAirPattern,
            devAirSweepingWind:this.changestatus.devAirSweepingWind,
            devAirTemperature:this.changestatus.devAirTemperature,
            devAirWindSpeed:this.changestatus.devAirWindSpeed,
            devBrightness:this.changestatus.nowStates.brightness,
            devColorTemperature:this.changestatus.devColorTemperature,
            devId:this.equipmentRow.jourDeviceId,
            devLowerLimitLumen:this.changestatus.devLowerLimitLumen,
            devLumenSpacing:this.changestatus.devLumenSpacing,
            devNumber:this.changestatus.devNumber,
            devPercentage:this.changestatus.devPercentage,
            devState:this.changestatus.devState,
            devUpperLimitLumen:this.changestatus.devUpperLimitLumen,
            devNumber:this.changestatus.csRoad2
          }
          }).then(res=>{
            this.loading = false
            this.dialogCaoZuoSheBei = false
            this.$message.success('控制成功')
          }).catch(err=>{
            this.loading = false
            this.$message.error(err.response.data.msg)
          })
        },
    //操作设备
      openLight(){
        this.changestatus.devState='开'
        this.changeStatus()
      },
      closeLight(){
        this.changestatus.devState='关'
        this.changeStatus()
      },
      openRoad(){
        let csRoad=this.changestatus.csRoad2
        this.changestatus.csRoad2=csRoad-1
        this.changestatus.devState='开'
        this.changeStatus()
      },
      closeRoad(){
        let csRoad=this.changestatus.csRoad3
        this.changestatus.csRoad2=csRoad-1
        this.changestatus.devState='关'
        this.changeStatus()
      },
      openCurtain(){
        this.changestatus.devPercentage='开'
        this.changeStatus()
      },
      closeCurtain(){
        this.changestatus.devPercentage='关'
        this.changeStatus()
      },
      spendCurtain(){
        this.changestatus.devPercentage='暂停'
        this.changeStatus()
      },
      airSelf(){
        this.changestatus.devAirPattern='自动'
        this.changeStatus()
        this.dialogMoShi=false
      },
      airCold(){
        this.changestatus.devAirPattern='制冷'
        this.changeStatus()
        this.dialogMoShi=false
      },
      airHot(){
        this.changestatus.devAirPattern='制热'
        this.changeStatus()
        this.dialogMoShi=false
      },
      airWet(){
        this.changestatus.devAirPattern='除湿'
        this.changeStatus()
        this.dialogMoShi=false
      },
      airWind(){
        this.changestatus.devAirPattern='送风'
        this.changeStatus()
        this.dialogMoShi=false
      },
      airWindSpeedSelf(){
        this.changestatus.devAirWindSpeed='自动'
        this.changeStatus()
        this.dialogFengSu=false
      },
      airWindSpeedHeight(){
        this.changestatus.devAirWindSpeed='高'
        this.changeStatus()
        this.dialogFengSu=false
      },
      airWindSpeedMiddle(){
        this.changestatus.devAirWindSpeed='中'
        this.changeStatus()
        this.dialogFengSu=false
      },
      airWindSpeedLow(){
        this.changestatus.devAirWindSpeed='低'
        this.changeStatus()
        this.dialogFengSu=false
      },
      airSweepingWindSelf(){
        this.dialogSaoFeng=false
        this.changestatus.devAirSweepingWind='自动'
        this.changeStatus()
      },
      airSweepingWindHand(){
        this.dialogSaoFeng=false
        this.changestatus.devAirSweepingWind='手动'
        this.changeStatus()
      },
      airTemperatureAdd(){
        // console.log(this.changestatus.devAirTemperature)
        if(this.changestatus.devAirTemperature<30){
        this.changestatus.devAirTemperature=parseInt(this.changestatus.devAirTemperature)+1
        this.changeStatus()
        }
      },
      airTemperatureMinus(){
        if(this.changestatus.devAirTemperature>16){
        this.changestatus.devAirTemperature=parseInt(this.changestatus.devAirTemperature)-1
        // this.changestatus.nowStates.temperature=''
        this.changeStatus()
        }
      },
      spaceLumenChange(){
        if(this.changestatus.devLumenSpacing>65535 || this.changestatus.devLumenSpacing<1 || isNaN(this.changestatus.devLumenSpacing) || (this.changestatus.devLumenSpacing)%1 != 0){
          this.$message.warning('请输入合法值')
          this.changestatus.devLumenSpacing=''
        }else{
        this.dialogSpaceLumen=false
        this.changestatus.devLumenSpacing=parseInt(this.changestatus.devLumenSpacing)
        this.changeStatus()
        }
      },
      minLumenChange(){
        if(this.changestatus.devUpperLimitLumen>65535 || this.changestatus.devUpperLimitLumen<1 || isNaN(this.changestatus.devUpperLimitLumen)
          || (this.changestatus.devUpperLimitLumen)%1 != 0){
          this.$message.warning('请输入合法值')
          this.changestatus.devUpperLimitLumen=''
        }else{
        this.dialogMinLumen=false
        this.changestatus.devUpperLimitLumen=parseInt(this.changestatus.devUpperLimitLumen)
        this.changeStatus()
        }
      },
      maxLumenChange(){
        if(this.changestatus.devLowerLimitLumen>65535 || this.changestatus.devLowerLimitLumen<1 || isNaN(this.changestatus.devLowerLimitLumen) || (this.changestatus.devLowerLimitLumen)%1 != 0){
          this.$message.warning('请输入合法值')
          this.changestatus.devLowerLimitLumen=''
        }else{
        this.dialogMaxLumen=false
        this.changestatus.devLowerLimitLumen=parseInt(this.changestatus.devLowerLimitLumen)
        this.changeStatus()
        }
      },
      openDoor(){//一键开门
        this.loading = true
        this.$axios({
          method:'put',
          url:'/campus/facePersons/openDoor',
          params:{deviceKey:this.changestatus.devSn}
        }).then(res=>{
          if(res.data.status==200){
            this.loading = false
            this.$message.success('开门成功')
          }
        }).catch(err=>{
          this.loading = false
          this.$message.error(err.response.data.msg)
        })
      },
      identify(a){//获取开门记录
        if(this.faceForm.time){
          var startTime=this.faceForm.time[0]
          var endTime=this.faceForm.time[1]
        }
        this.dialogIdentify=true
        this.$axios({
          method:'get',
          url:'/campus/faceRecognitionInformations',
          params:{
            limit:this.faceForm.limit,
            cursor:this.faceForm.cursor,
            id:this.changeStatus.jourDeviceId,
            jftiPersonName:this.faceForm.jftiPersonName,
            startTime,
            endTime
          }
        }).then(res=>{
          for(let i of res.data.result.records){
            if(i.jftiType==1){
              i.jftiType='成功'
            }else{
              i.jftiType='失败'
            }
          if(i.jftiRecMode==1){
              i.jftiRecMode='刷脸'
            }else if(i.jftiRecMode==2){
              i.jftiRecMode='刷卡'
            }else if(i.jftiRecMode==3){
              i.jftiRecMode='脸&卡双重认证'
            }else if(i.jftiRecMode==4){
              i.jftiRecMode='人证比对'
            }
          }
          this.faceForm.faceIdList=res.data.result.records
          this.faceForm.total=res.data.result.total
        }).catch(err=>{
          this.$message.error(err.response.data.msg)
        })
      },
      jftiPersonNameChange(){//输入名字查询
        this.faceForm.cursor=1
        this.identify()
      },
      identifyCurrent(cursor){//分页
        this.faceForm.cursor=cursor
        this.identify()
      },
      identifyClose(){//识别记录窗口关闭
        this.faceForm.time=''
        this.faceForm.jftiPersonName=''
        this.faceForm.dfdnDoorSn=''
      },
      restart(){//重启
        this.loading = true
        this.$axios({
          method:'post',
          url:'/campus/facePersons/restart',
          params:{deviceKey:this.changestatus.devSn}
        }).then(res=>{
          if(res.data.status==200){
              this.loading = false
          this.$message.success('重启成功')}
        }).catch(err=>{
          this.loading = false
          this.$message.error(err.response.data.msg)
        })
      },
      magneticDoor(){//绑定门磁按钮获取所有门磁设备
        this.dialogMagnetic=true
        this.$axios({
          method:'get',
          url:'/campus/facePersons/magnetism'}).then(res=>{
          this.faceForm.magneticList=res.data.result
        }).catch(err=>{
          this.$message.error(err.response.data.msg)
        })
      },
      imgShow(url){
        // console.log(row)
        this.dialogImageUrl=url
        this.dialogImg=true
      },
      magneticSave(){//绑定门磁保存按钮
        if(this.faceForm.dfdnDoorSn){
          let dfdnFaceSn=this.changestatus.devSn
          let dfdnDoorSn=this.faceForm.dfdnDoorSn
          this.loading = true
          this.$axios({
            method:'post',
            url:'/campus/facePersons/binding',
            data:{
              dfdnFaceSn,
              dfdnDoorSn
            }
          }).then(res=>{
            this.loading = false
            this.dialogMagnetic=false
            this.$message.success('绑定成功')
          }).catch(err=>{
            this.loading = false
            this.$message.error(err.response.data.msg)
          })
        }else{
          this.$message.warning('请选择一个门磁设备')
        }
      },
      magneticSel(row){//选中一个门磁设备
        this.faceForm.dfdnDoorSn=row.devSn
      },
      deleteBind(){//解除绑定按钮
        let deviceId=parseInt(this.changeStatus.jourDeviceId)
        this.loading = true
        this.$axios({
          method:'delete',
          url:'/campus/facePersons/deviceId',
          params:{deviceId}
        }).then(res=>{
          this.loading = false
          this.getDataRoomEquipment()
          this.dialogCaoZuoSheBei = false
          this.$message.success('解绑成功')
        }).catch(err=>{
          this.loading = false
          this.$message.error(err.response.data.msg)
        })
      },
    //情景模式
      scenarioModeChange(){//获取情景模式下拉菜单
        this.$axios({
          method: 'get',
          url: '/common2/scenarioPatternDescriptions'
        }).then(res => {
          if(res.data.status==200){
            this.dataScenarioMode = res.data.result
            this.spdId = this.dataScenarioMode[0].spdId
            this.getScenarioMode()
          }
        }).catch(err => {
          console.log(err)
        })
      },
      getScenarioMode(){//获取情景模式
        //获取设备
          let equipment = []
          this.$axios({
            method: 'get',
            url: `/common2/house4stores/${this.homeRow.hsId}/devices`,
            // url: `/campus/house4stores/${1123}/devices`,
            params: {
              scenarios: true,
              scenariosPanel: false
            }
          }).then(res => {
            this.IntelligenceControl = ''
            if(res.data.result.records.length >= 1){
              equipment = res.data.result.records
              console.log(this.row)
              console.log(equipment[1].devSn)
              //获取情景模式
                let mode = []
                this.$axios({
                  method: 'get',
                  url: `/common2/scenarioPatternTables/house4stores/${this.homeRow.hsId}/scenarios/${this.spdId}`,
                  // url: `/devicePlace/deviceSecondTypes`,
                  // params: {hsIds: this.row.hsId}
                }).then(res => {
                  console.log(res)
                  mode = JSON.parse(res.data.result.jsroImsState)
                  //拼接
                  console.log(mode)
                  this.equipmentObj(equipment,mode)
                  this.IntelligenceControl = res.data.result.jsroIntelligentControlEnabled.toString()
                }).catch(err => {
                  console.log(err)
                  this.equipmentObj(equipment,mode)
                })
                console.log(mode)
            }
          }).catch(err => {
            console.log(err)
          })

      },
      equipmentObj(equipment,mode){//拼接设备情景数组
        for (let i = 0 ; i < equipment.length ; i++) {
          equipment[i].isEnable = 0
          if(this.secondId.airConditioner.type.indexOf(equipment[i].devSecondType) > -1){//空调
            equipment[i].jsroImsState = {
              temperature: '',
              pattern: '',
              windSpeed: '',
            }
          }else if(this.secondId.coolingAndHeatingLamp.type.indexOf(equipment[i].devSecondType) > -1){//冷暖灯
            equipment[i].jsroImsState = {
              brightness: '',
              colorTemperature: '',
            }
          }else if(this.secondId.adjustingLamp.type.indexOf(equipment[i].devSecondType ) > -1){//调节灯
            equipment[i].jsroImsState = {
              brightness: '',
            }
          }else{
            equipment[i].jsroImsState = {}
          }
          equipment[i].jsroImsState.devFirstType = equipment[i].devFirstType
          equipment[i].jsroImsState.devSecondType = equipment[i].devSecondType
          equipment[i].jsroImsState.devRoad = equipment[i].devRoad
          equipment[i].jsroImsState.state = '关'
          equipment[i].jsroImsState.sn = equipment[i].devSn
          for (let j = 0 ; j < mode.length ; j++) {//循环赋值
            if(equipment[i].devSn == mode[j].sn){
            console.log(`${equipment[i].devSn}+${mode[j].sn}`)
              equipment[i].isEnable = 1
              for (let k in equipment[i].jsroImsState){
                for (let l in mode[j])
                  if(k == l){
                    equipment[i].jsroImsState[k] = mode[j][l]
                  }
              }
            }
          }
        }
        this.dataRoomMode = equipment
        console.log(this.dataRoomMode)
      },
      otherSettings(row){//其他设置
        this.rowButton = row
        if(this.secondId.airConditioner.type.indexOf(this.rowButton.devSecondType) > -1){
          for (let i in this.rowButton.jsroImsState) {
            for (let j in this.secondId.airConditioner.mode) {
              if (i == j) {
                this.secondId.airConditioner.mode[i] = this.rowButton.jsroImsState[j]
              }
            }
          }
        }else if(this.secondId.coolingAndHeatingLamp.type.indexOf(this.rowButton.devSecondType) > -1){
          for (let i in this.rowButton.jsroImsState) {
            for (let j in this.secondId.coolingAndHeatingLamp.mode) {
              if (i == j) {
                this.secondId.coolingAndHeatingLamp.mode[i] = Number(this.rowButton.jsroImsState[j])
              }
            }
          }
        }else if(this.secondId.adjustingLamp.type.indexOf(this.rowButton.devSecondType) > -1){
          for (let i in this.rowButton.jsroImsState) {
            for (let j in this.secondId.adjustingLamp.mode) {
              if (i == j) {
                this.secondId.adjustingLamp.mode[i] = Number(this.rowButton.jsroImsState[j])
              }
            }
          }
        }
        this.dialogControlEquipment = true
      },
      assignmentControlEquipment(){//详细设置关闭赋值
        let arr = {}
        if(this.secondId.airConditioner.type.indexOf(this.rowButton.devSecondType) > -1){
          arr = this.secondId.airConditioner.mode
        }else if(this.secondId.coolingAndHeatingLamp.type.indexOf(this.rowButton.devSecondType) > -1){
          arr = this.secondId.coolingAndHeatingLamp.mode
        }else if(this.secondId.adjustingLamp.type.indexOf(this.rowButton.devSecondType) > -1){
          arr = this.secondId.adjustingLamp.mode
        }
        for (let i = 0 ; i < this.dataRoomMode.length ; i++ ) {
          if (this.dataRoomMode[i].devSn == this.rowButton.devSn) {
            for (let j in this.dataRoomMode[i].jsroImsState) {
              for (let k in arr) {
                if (j == k) {
                  this.dataRoomMode[i].jsroImsState[j] = arr[k]
                }
              }
            }
          }
        }
        console.log(this.rowButton)
      },
      roomDetailsSave(){//情景模式保存
        if(this.IntelligenceControl == ''){
          this.$message.warning('请选择智控')
        }else{
          this.loading = true
          let arr = []
          for (let i = 0 ; i < this.dataRoomMode.length ; i++) {
            // console.log(this.dataRoomMode[i].isEnable)
            if (this.dataRoomMode[i].isEnable == 1) {
              let instructions = this.dataRoomMode[i].jsroImsState
              arr.push(instructions)
            }
          }
          let scenarioPatternTablePARM = {
            hsIds: [this.row.hsId],
            jsroImsState: JSON.stringify(arr),
            spdId: this.spdId,
            jsroIntelligentControlEnabled: Number(this.IntelligenceControl)
          }
          this.$axios({
            method: 'post',
            url: '/common2/scenarioPatternTables/alone',
            data: scenarioPatternTablePARM
          }).then(res => {
            console.log(res)
              this.loading = false
              this.$message.success('添加成功')
              this.dialogRoomDetails = false

          }).catch(err => {
            this.isError(err,this)
          })
        }
      },
    //弹窗关闭
      dialogInfoClose(){
        this.activeName=''
        this.assetForm.cursor=1
        this.approvalParams.cursor=1
        this.taskCur=1
        this.commonQuery.startTime =''
        this.commonQuery.endTime=''
        this.Time=''
        this.commonQuery.cursor=1
        Object.assign(this.$data.assetForm, this.$options.data().assetForm)
      },
    //区域管理查询相关
      getAllArea() {//获取区域管理数据列表
        this.$axios({
          method: 'get',
          url: `/common2/regionalDevices/${this.homeRow.hsId}`,
          // url: `/regionalDevices/${136}`,
        }).then(res => {
          this.areaData = res.data.result
          console.log(this.areaData)
          //查询区域名称对应的位置信息
          // for(let i in this.areaData){
          //   console.log(this.areaData[i].jrId)
          //   this.$axios({
          //     method: 'get',
          //     url: `/positions/${this.areaData[i].jrId}`,
          //   }).then(res => {
          //     console.log(res)
          //   }).catch(err => {
          //     this.$message({
          //       showClose: true,
          //       duration: 6000,
          //       message: `${err.response.data.msg}`,
          //       type: 'error'
          //     })
          //   })
          // }
        }).catch(err => {
          this.areaData = [];
          this.isError(err,this)
        })
      },
      addAreaPost(){
        if(this.areaName == ''){
          this.$message.warning('区域名称不能为空')
        }else{
          this.$axios({
            method: 'post',
            url: `/common2/regionals`,
            data:{
              hsId: this.homeRow.hsId,
              jrName: this.areaName
            }
          }).then(res => {
            this.loading = false
            this.$message.success('添加成功')
            this.getAllArea() //获取区域管理列表信息
            this.addArea = false
          }).catch(err => {
            this.loading = false
            this.addArea = false
            this.isError(err,this)
          })
        }
      },
      closeAddArea(){//添加区域清空
        this.areaName = ''
      },
      addAreaClick(){
        this.addAreaTitle = '新增区域'
        this.addAreaState = false
        this.addArea = true
      },
      deleteArea(row){//删除区域
        this.$axios({
          method: 'delete',
          url: `/common2/regionals/${row.jrId}`,
        }).then(res => {
          this.$message.error('删除成功')
          this.getAllArea() //更新列表信息
        }).catch(err => {
          this.isError(err,this)
        })
      },
      monitoringClick(){//监测设备事件
        if(this.rowArea == ''){
          this.$message.warning('请选择一条信息')
        }else{
          this.title = this.monitoringRelated.title
          this.ggjrdDeviceType = this.monitoringRelated.jrdDeviceType
          this.monitoringData()//获取监控设备信息
          this.monitoringGiveData()
        }
      },
      publicClick(){//公共设备事件
        if(this.rowArea == ''){
          this.$message.warning('请选择一条信息')
        }else{
          this.monitoringBox = true
          this.title = this.publicRelated.title
          this.ggjrdDeviceType = this.publicRelated.jrdDeviceType
          this.monitoringData()//获取监控设备信息
          this.monitoringGiveData()
        }
      },
      locationClick(){//位置设备事件
        if(this.rowArea == ''){
          this.$message.warning('请选择一条信息')
        }else{
          this.monitoringBox = true
          this.title = this.locationRelated.title
          this.ggjrdDeviceType = this.locationRelated.jrdDeviceType
          this.monitoringData()//获取监控设备信息
          this.monitoringGiveData()
        }
      },
      monitoringData(){//获取监测设备数据
        console.log(this.homeRow);
        this.$axios({
          method: 'get',
          url: `/common2/regionalDevices`,
          params: {
            jrdDeviceType: this.ggjrdDeviceType,
            // hsId: '1123'
            hsId: this.homeRow.hsId
          }
        }).then(res => {
          this.monitoringBoxData = res.data.result.records
          this.monitoringBox = true
        }).catch(err => {
          this.isError(err,this)
        })
      },
      monitoringGiveData(){
        this.$axios({
          method: 'get',
          url: `/common2/regionalDevices/binding`,
          params: {
            jrId: this.rowArea.jrId,
            jrdDeviceType: this.ggjrdDeviceType
          }
        }).then(res => {
          this.GiveData = res.data.result
          this.shuttleBox.jourDeviceId = this.GiveData.map(String)
        }).catch(err => {
          this.isError(err,this)
        })
      },
      cleanMonitoring(){//清空监测设备穿梭框
        this.shuttleBox.jourDeviceId = []
      },
      currentChangeArea(row){//获取区域当前行信息
        this.rowArea = row
        console.log(this.rowArea)
      },
      monitoringBoxSave(){//绑定监测设备
        this.$axios({
          method: 'post',
          url: `/common2/regionalDevices`,
          data: {
            jrId: this.rowArea.jrId,
            jourDeviceIds: this.shuttleBox.jourDeviceId.map(Number),
            jrdDeviceType: this.ggjrdDeviceType
          }
        }).then(res => {
          this.monitoringBox = false
          this.$message.success('绑定成功')
          this.getAllArea()
        }).catch(err => {
          this.monitoringBox = false
          this.isError(err,this)
        })
      },
      roDblclickArea(row,column){//双击修改
        if(column.label === '区域名称'){
          this.areaName = row.jrName
          this.addAreaTitle = '修改区域'
          this.addAreaState = true
          this.addArea = true
        }else if(column.label === '监测设备'){
          this.monitoringClick()
        }else if(column.label === '公共设备'){
          this.publicClick()
        }else if(column.label === '位置设备'){
          this.locationClick()
        }
      },
      addAreaPut(){//修改区域
        this.$axios({
          method: 'put',
          url: `/common2/regionals/${this.rowArea.jrId}`,
          data: {
            jrName: this.areaName
          }
        }).then(res => {
          this.addArea = false
          this.$message.success('修改成功')
          this.getAllArea()
        }).catch(err => {
          this.addArea = false
          this.isError(err,this)
        })
      },
      locationDialogClick(row){//位置信息
        if(row == undefined){
          row = this.rowArea
        }
        this.$axios({
          method: 'get',
          url: `/common2/positionDevices/${row.jrId}`,
        }).then(res => {
          this.locationData = res.data.result
          console.log(this.locationData)
        }).catch(err => {
          this.locationData = []
          this.isError(err,this)
        })
        this.locationDialog = true
      },
      positionClick(){
        this.addPositionTitle = '添加位置'
        this.addPositionState = false
        this.addPosition = true
      },
      closeAddPosition(){//添加区域清空
        this.jpPositionName = ''
      },
      addPositionPost(){//保存位置
        if(this.jpPositionName == ''){
          this.$message.warning('位置名称不能为空')
        }else{
          this.$axios({
            method: 'post',
            url: `/common2/positions`,
            data: {
              hsId: this.homeRow.hsId,
              jrId: this.rowArea.jrId,
              jpPositionName: this.jpPositionName
            }
          }).then(res => {
            this.$message.success('添加成功')
            this.addPosition = false
            this.locationDialogClick()
          }).catch(err => {
            this.addPosition = false
            this.isError(err,this)
          })
        }
      },
      deletePosition(row){//删除位置
        this.$axios({
          method: 'delete',
          url: `/common2/positions/${row.jpId}`,
        }).then(res => {
          this.$message.error('删除成功')
          this.locationDialogClick() //更新列表信息
        }).catch(err => {
          this.isError(err,this)
        })
      },
      currentChangePosition(row){
        this.rowPosition = row
        console.log(this.rowPosition)
      },
      roDblclickPosition(row,column){//双击修改列表信息
        if(column.label === '位置名称'){
          this.jpPositionName = row.jpPositionName
          this.addPositionState = true
          this.addPositionTitle = '修改位置'
          this.addPosition = true
        }else if(column.label === '个人设备'){
          this.equipmentClick()
        }else if(column.label === '人员信息'){
          this.personnelClick()
        }
      },
      addPositionPut(){//修改信息
        this.$axios({
          method: 'put',
          url: `/common2/positions/${this.rowPosition.jpId}`,
          data: {
            hsId: this.rowPosition.hsId,
            jpPositionName: this.jpPositionName,
            jrId: this.rowPosition.jrId
          }
        }).then(res => {
          this.$message.success('修改成功')
          this.locationDialogClick() //更新列表信息
          this.addPosition = false
        }).catch(err => {
          this.addPosition = false
          this.isError(err,this)
        })
      },
      equipmentData(){//获取监测设备数据
        this.$axios({
          method: 'get',
          url: `/common2/positionDevices/position/device`,
          params: {
            // jrdDeviceType: 3,
            // hsId: '1123'
            jrId: this.rowPosition.jrId
          }
        }).then(res => {
          console.log(res);
          this.equipmentBoxData = res.data.result.records
        }).catch(err => {
          this.isError(err,this)
        })
      },
      equipmentBoxSave(){//绑定个人设备
        this.$axios({
          method: 'post',
          url: `/common2/positionDevices`,
          data: {
            jpId: this.rowPosition.jpId,
            jourDeviceIds: this.equipmentShuttle.jourDeviceId.map(Number)
          }
        }).then(res => {
          this.equipmentBox = false
          this.$message.success('绑定成功')
          this.locationDialogClick()
        }).catch(err => {
          this.equipmentBox = false
          this.isError(err,this)
        })
      },
      equipmentGiveData(){//个人设备穿梭框赋值
        this.shuttleGiveData = []
        this.$axios({
          method: 'get',
          url: `/common2/positionDevices/${this.rowPosition.jpId}/binding`,
        }).then(res => {
          this.GiveData = res.data.result.jourDeviceId
          for(let i in this.GiveData){
            this.shuttleGiveData[i] = this.GiveData[i].toString()
          }
          this.equipmentShuttle.jourDeviceId = this.shuttleGiveData
        }).catch(err => {
          this.isError(err,this)
        })
      },
      equipmentClick(){//设备绑定事件
        if(this.rowPosition == '' || this.rowPosition == null){
          this.$message.warning('请选择一条信息')
        }else{
          this.equipmentBox = true
          this.equipmentData()//获取绑定设备信息
          this.equipmentGiveData()
        }
      },
      cleanLocation(){//清空绑定个人设备穿梭框
        this.equipmentShuttle.jourDeviceId = []
      },
      personnelClick(){
        if(this.rowPosition == '' || this.rowPosition == null){
          this.$message.warning('请选择一条信息')
        }else{
          this.$axios({
            method: 'get',
            url: `/common1/users`,
            params: {
              suState: '正常'
            }
          }).then(res => {
            this.options = []
            let userData = res.data.result.records
            for(let i in userData){
              let obj = {}
              obj.label = userData[i].suStaffName
              obj.value = userData[i].userCoding
              this.options.push(obj)
            }
            this.addPersonnel = true
          }).catch(err => {
            this.isError(err,this)
          })
        }
      },
      addPersonnelPost(){
        this.$axios({
          method: 'put',
          url: `/common2/positions/${this.rowPosition.jpId}`,
          data: {
            userCoding: this.userCoding
          }
        }).then(res => {
          if(this.userCoding == 0){
            this.$message.success('解绑成功')
          }else{
            this.$message.success('绑定成功')
          }
          this.locationDialogClick()
          this.addPersonnel = false
        }).catch(err => {
          this.addPersonnel = false
          this.isError(err,this)
        })
      },
      addPersonnelSave(){//绑定人员
        this.userCoding = this.bindingPersonnel[0]
        this.addPersonnelPost()
      },
      unbundlingClick(){//解定人员
        if(this.rowPosition == '' || this.rowPosition == null){
          this.$message.warning('请选择一条信息')
        }else{
          this.userCoding = 0
          this.addPersonnelPost()
        }
      },
      closeBindingPersonnel(){//选择人员清空
        this.bindingPersonnel = ''
      },
      banEquipmentClick(){//禁止设备
        this.banTitle = '选择禁止设备'
        this.banState = false
        this.publicGet()
      },
      banBlackoutClick(){
        this.banTitle = '选择禁止断电'
        this.banState = true
        this.publicGet()
      },
      publicGet(){
        this.$axios({
          method: 'get',
          // url: `/common2/devices/1123/hsId`,
          url: `/common2/devices/${this.homeRow.hsId}/hsId`,
        }).then(res => {
          this.banEquipmentBoxData = res.data.result.records
          this.banEquipmentBox = true
          if(this.banState){
            for(let i in res.data.result.records){
              if(res.data.result.records[i].devKwhState == '1'){
                this.banEquipmentGiveArr.push(res.data.result.records[i].jourDeviceId)
              }
            }
            this.banEquipmentShuttle.jourDeviceId = this.banEquipmentGiveArr
          }else{
            for(let i in res.data.result.records){
              if(res.data.result.records[i].devEnableState == '0'){
                this.banEquipmentGiveArr.push(res.data.result.records[i].jourDeviceId)
              }
            }
            this.banEquipmentShuttle.jourDeviceId = this.banEquipmentGiveArr
          }
        }).catch(err => {
          this.isError(err,this)
        })
      },
      cleanBanEquipment(){
        this.banEquipmentShuttle.jourDeviceId = []
        this.banEquipmentGiveArr = []
      },
      banEquipmentBoxSave(){//禁止设备保存
        var arr=[];
        for (let i in this.banEquipmentBoxData){
          let obj={
            jourDeviceId:this.banEquipmentBoxData[i].jourDeviceId,
            devEnableState:1
          }
          arr.push(obj);
        }
        for (let i in arr){
          for   (let j in this.banEquipmentShuttle.jourDeviceId){
            if (arr[i].jourDeviceId==this.banEquipmentShuttle.jourDeviceId[j]){
              arr[i].devEnableState=0;
            }
          }
        }
        this.banPuclicPost(arr)
      },
      banPuclicPost(arr){
        this.$axios({
          method: 'post',
          url: `/common2/devices/prohibit/device`,
          data: arr
        }).then(res => {
          this.$message.success('保存成功')
          this.banEquipmentBox = false
        }).catch(err => {
          this.banEquipmentBox = false
          this.isError(err,this)
        })
      },
      banBlackoutBoxSave(){
        var arr=[];
        for (let i in this.banEquipmentBoxData){
          let obj={
            jourDeviceId:this.banEquipmentBoxData[i].jourDeviceId,
            devKwhState:0
          }
          arr.push(obj);
        }
        for (let i in arr){
          for   (let j in this.banEquipmentShuttle.jourDeviceId){
            if (arr[i].jourDeviceId==this.banEquipmentShuttle.jourDeviceId[j]){
              arr[i].devKwhState=1;
            }
          }
        }
        this.banPuclicPost(arr)
      }
    }
  }
</script>

<style lang='scss' scoped>

</style>
